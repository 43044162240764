import CardLayerActions from "@components/CardLayerActions";
import { CheckListIcon } from "@components/Icons";
import { useUserState } from "@context/UserContext";
import {
  TextField,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  CircularProgress,
  Button,
  withStyles,
} from "@material-ui/core";
import { capitalizeFirstLetterFullString, maskCNPJ } from "@utils/index";
import { useFormikContext } from "formik";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Checkbox, Grid } from "@mui/material";
import CalendarRange from "@components/CalendarRange";
import { useReclamationContext } from "@context/ReclamationContext/ReclamationUserContext";
import { IReclamationFormValues } from "./interface";

const CustomCheckbox = withStyles((theme) => ({
  root: {
    border: "0.5px",
    color: theme.palette.primary.main,
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))((props) => {
  const theme = useTheme();
  return (
    <Checkbox
      color="default"
      {...props}
      style={{ color: theme.palette.primary.main }}
    />
  );
});

const ReclamationForm: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const redColor = theme.palette.primary.main;
  const whiteColor = theme.palette.text.hint;

  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const userState = useUserState();
  const {
    loadingDataFilterReclamation,
    setRangeDate,
  } = useReclamationContext();

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<IReclamationFormValues>();

  const renderCPNJ = () => {
    return userState.listCNPJ.map((ele, idx) => {
      return (
        <MenuItem
          key={ele.BpID ? ele.BpID : ele.CNPJ}
          value={ele.BpID ? ele.BpID : ele.CNPJ}
          className={classes.itemSelect}
        >
          <Grid container>
            <Grid item>
              <CustomCheckbox
                //@ts-ignore
                checked={
                  ele.BpID
                    ? values.cnpj.indexOf(ele.BpID) > -1
                    : values.cnpj.indexOf(ele.CNPJ) > -1
                }
              />
            </Grid>
            <Grid item style={{ marginTop: "-3px", padding: "0" }}>
              <Typography className={classes.itemSelectTitle}>
                {capitalizeFirstLetterFullString(ele.companyName)}
              </Typography>
              <Typography className={classes.itemSelectSubTitle}>
                {maskCNPJ(ele.CNPJ)}
              </Typography>
              {ele.BpID && (
                <Typography className={classes.itemSelectSubTitle}>
                  BP: {ele.BpID}
                </Typography>
              )}
            </Grid>
          </Grid>
        </MenuItem>
      );
    });
  };

  const handleOnChangeIdReclamation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFieldValue("ref_id", event.target.value.replace(/\D/g, ""));
  };

  return (
    <Grid container sx={{ border: "0px solid red" }}>
      <Grid item xs={12}>
        <CardLayerActions title="Registros de atendimento" icon={CheckListIcon}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={3}
              style={{
                marginBottom: isMobile || isTablet ? "25px" : "",
                height: "78px",
              }}
            >
              <TextField
                style={{
                  width: isMobile || isTablet ? "100%" : "calc(100% - 30px)",
                }}
                className={classes.textfield}
                label="ID do registro"
                variant="outlined"
                id="ref_id"
                type="text"
                value={values.ref_id}
                onChange={handleOnChangeIdReclamation}
                onBlur={() => setFieldTouched("ref_id", true)}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              style={{
                marginBottom: isMobile || isTablet ? "25px" : "",
              }}
            >
              <FormControl
                fullWidth
                className={classes.formControl}
                variant="outlined"
              >
                <InputLabel
                  htmlFor="freightType"
                  className={classes.inputLabel}
                  style={{ fontWeight: !!touched.cnpj ? "bold" : "none" }}
                >
                  Razão social
                </InputLabel>
                <Select
                  style={{
                    width: isMobile || isTablet ? "100%" : "calc(100% - 30px)",
                  }}
                  className={classes.customSelect}
                  error={!!errors.cnpj && !!touched.cnpj}
                  onBlur={() => setFieldTouched("cnpj", true)}
                  value={values.cnpj}
                  onChange={({ target }) => {
                    setFieldValue("cnpj", target.value);
                  }}
                  name="cnpj"
                  IconComponent={ExpandMoreIcon}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                  multiple
                  renderValue={(selected) => (
                    <div
                      style={{
                        display: "flex",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {(selected as string[]).map((value) => {
                        return (
                          <Typography>
                            {
                              userState.listCNPJ.find((CNPJ) =>
                                CNPJ.BpID
                                  ? CNPJ.BpID === value
                                  : CNPJ.CNPJ === value
                              )?.companyName
                            }
                            {(selected as string[]).length > 1 && " / "}
                          </Typography>
                        );
                      })}
                    </div>
                  )}
                >
                  {renderCPNJ()}
                </Select>
              </FormControl>
              <FormHelperText
                error={!!errors.cnpj && !!touched.cnpj}
                style={{ marginLeft: "14px" }}
              >
                {!!touched.cnpj && errors.cnpj}
              </FormHelperText>
            </Grid>

            <Grid
              item
              md={3}
              xs={12}
              style={{
                marginBottom: isMobile || isTablet ? "25px" : "",
              }}
            >
              <CalendarRange
                //@ts-ignore
                name="date_range"
                label="Data ou período"
                value={values.date_range}
                onChange={(date_range) => {
                  setFieldValue("date_range", date_range);
                  setFieldTouched("startDate", true);
                  setFieldTouched("endDate", true);
                  setRangeDate(
                    date_range?.map((cont) => (cont ? cont : new Date())) ?? []
                  );
                }}
                onBlur={() => {
                  setFieldTouched("startDate", true);
                  setFieldTouched("endDate", true);
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="submit"
                style={{
                  fontFamily: "Montserrat",
                  borderRadius: "2px",
                  fontWeight: "bold",
                  textTransform: "none",
                  background: redColor,
                  color: whiteColor,
                  padding: isMobile ? "10px 30px" : "10px 30px",
                  boxShadow: "none",
                  fontSize: isMobile ? "12px" : "",
                  height: "56px",
                }}
                startIcon={
                  loadingDataFilterReclamation && (
                    <CircularProgress
                      color="secondary"
                      style={{ height: "2rem", width: "2rem" }}
                    />
                  )
                }
              >
                {loadingDataFilterReclamation ? "Consultando" : "Consultar"}
              </Button>
            </Grid>
          </Grid>
        </CardLayerActions>

        <Grid container justifyContent="center">
          <Typography
            style={{
              color: "#3E3D3D",
              fontFamily: "DM Sans",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            Resultados da pesquisa
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { ReclamationForm };

const useStyles = makeStyles((theme) =>
  createStyles({
    title: {
      color: theme.palette.primary.highlightedplus, //"#3E3D3D",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      lineHeight: "normal",
      paddingTop: "20px",
    },
    inputLabel: {
      position: "absolute",
      color: theme.palette.primary.highlightedplus,
      fontFamily: "Montserrat",
      fontSize: "1.6rem",
      backgroundColor: theme.palette.text.hint,
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
    textfield: {
      width: "100%",
      fontFamily: "Montserrat",
      "& label": {
        // Controle de estilo antes de digitar
        color: theme.palette.primary.highlightedplus,
        fontWeight: 400,
        fontSize: "1.6rem",
        borderRadius: "8px",
        opacity: "0.6",
      },
      "& .MuiInputLabel-shrink": {
        // Controle de estilo apos campo digitado
        fontSize: "1.6rem",
        fontWeight: 700,
        color: theme.palette.primary.highlightedplus,
        opacity: 1,
        backgroundColor: theme.palette.text.hint, // "#FFF"
        paddingRight: 6,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        opacity: "0.6",
      },
    },
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    itemSelectTitle: {
      fontSize: "1.6rem",
      fontWeight: "bold",
    },
    itemSelectSubTitle: {
      fontSize: "1.2rem",
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "rgba(114, 101, 81, 0.20)",
      },

      "& .MuiSvgIcon-root": {
        fill: theme.palette.primary.main,
      },

      "&:focus": {
        backgroundColor: theme.palette.text.hint,
      },
      borderRadius: "8px",
    },
    formControl: {
      borderRadius: "8px",
      height: "56px",
      width: "100%",
    },
    gridInput: {
      marginRight: "2rem",
      marginBottom: "2rem",
      height: "50px",
    },
  })
);
