import { useFlags } from "flagsmith/react";
import React, { PropsWithChildren, ReactElement } from "react";
import { Redirect } from "react-router-dom";

import { SIDEBAR_USER_FLAGS } from "@utils/featureFlags";

import ROUTES from "@config/routes";
import { useAuth } from "@context/auth/AuthContext";
import { Roles } from "@shared/model";

interface RouteGuardProps extends PropsWithChildren<{}> {
  children: ReactElement;
  rules?: (_: string) => boolean;
  flag?: string;
}

const RouteGuard: React.FC<RouteGuardProps> = ({ children, rules, flag }) => {
  const { flag_for_access_management } = useFlags([
    "flag_for_access_management",
  ]);

  const flags = useFlags(SIDEBAR_USER_FLAGS);

  const { permissionSet } = useAuth();
  const isAdmin = permissionSet.ROLES.some(
    (role) => role === Roles.ADMIN || role === Roles.ADMINPORTAL
  );

  // NOTE: Para confirmar se o perfil é de motorista, o SYSTEM_MODULES deve conter apenas 1 resultado.
  const IS_PROFILE_ONLY_DRIVER =
    permissionSet.SYSTEM_MODULES.includes("portal_cliente:driver") &&
    permissionSet.SYSTEM_MODULES.length === 1;

  const hasAccessProfileDriver = () => {
    return flag_for_access_management.enabled && IS_PROFILE_ONLY_DRIVER;
  };

  const hasAccess = () => {
    if (!flag_for_access_management.enabled) {
      return true;
    }

    const isAuthorized = permissionSet.SYSTEM_MODULES.some((authPermission) => {
      const isRules = rules && rules(authPermission);
      const isFlag = Boolean(flag) ? flag && flags[flag].enabled : true;

      return isRules && isFlag;
    });

    return isAuthorized;
  };

  return isAdmin || hasAccess() ? (
    children
  ) : hasAccessProfileDriver() ? (
    <Redirect to={ROUTES.USER_ROUTES.ROADLOAD} />
  ) : (
    <Redirect to={ROUTES.USER_ROUTES.NOTFOUND} />
  );
};

export default RouteGuard;
