import { IGetBpIdDTO } from "@modules/user/dtos/IGetBpIdDTO";
import { SharedProps, VariantType, useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { IGetBpIdService } from "@modules/user/models/IGetBpIdService";

export interface SnackbarProps extends SharedProps {
  message: string;
  variant: VariantType;
}

/**
 * Hook personalizado para buscar e gerenciar CNPJs relacionados a um determinado BP ID de transportadora.
 */

const useCNPJsFromBpId = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [bpIdData, setBpIdData] = useState<IGetBpIdDTO | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isfinished, setIsfinished] = useState(false);

  const iocContext = useIoCContext();

  const getBpIdService = iocContext.serviceContainer.get<IGetBpIdService>(
    Types.Users.IGetBpIdService
  );

  const fetchCNPJsFromBPID = useCallback(
    async (bpId: string, clearGroupCNPJs: () => void) => {
      if (!bpId) {
        enqueueSnackbar("BP ID é obrigatório para buscar os CNPJs.", {
          variant: "warning",
        });
        return;
      }

      try {
        setIsLoading(true);
        clearGroupCNPJs();

        const data = await getBpIdService.execute(bpId);

        //NOTE: Para testes, usar o CNPJsFromBpIdMock
        setBpIdData(data);

        if (data) {
          enqueueSnackbar("Lista de CNPJs carregada com sucesso", {
            variant: "success",
          });
        } else {
          enqueueSnackbar(
            "Não foi encontrado registros referente ao BPID informado",
            {
              variant: "error",
            }
          );
        }
      } catch (error) {
        enqueueSnackbar("Ocorreu um erro ao listar CPNJs e CPFs", {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
        setIsfinished(true);
      }
    },
    [enqueueSnackbar, getBpIdService]
  );

  return { bpIdData, isLoading, isfinished, fetchCNPJsFromBPID };
};

export default useCNPJsFromBpId;
