import * as Yup from "yup";
import { capitalizeWords } from "../../../../utils";

const FormEditUserValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("Preencha o primeiro nome")
    .trim()
    .transform((firstName: string) => {
      return capitalizeWords(firstName);
    }),
  lastName: Yup.string()
    .required("Preencha o último nome")
    .trim()
    .transform((lastName: string) => {
      return capitalizeWords(lastName);
    }),

  email: Yup.string()
    .email("Digite um email válido")
    .lowercase()
    .trim()
    .required("Preencha o email"),
  profile: Yup.string().required("Selecione um perfil"),
  attributes: Yup.object().shape({
    BPID: Yup.string()
    .when('profile', {
      is: (profile) => profile === 'CARRIER', // Transportadora
      then: Yup.string()
        .required("Preencha o BP ID ")
        .trim()
        .transform((BPID: string) => {
          return capitalizeWords(BPID);
        }),
      otherwise: Yup.string().nullable(), // Permite que o campo seja nulo se a condição não for atendida
    }),
    INTERNAL_NAME: Yup.string()
      .when('profile', {
        is: (profile) => profile === 'CARRIER', // Transportadora
        then: Yup.string()
          .required("Preencha o INTERNAL_NAME ")
          .trim()
          .transform((INTERNAL_NAME: string) => {
            return capitalizeWords(INTERNAL_NAME);
          }),
        otherwise: Yup.string().nullable(), // Permite que o campo seja nulo se a condição não for atendida
      }),
    CNPJ: Yup.array()
      .of(
        Yup.object().shape({
          CNPJ: Yup.string().required().trim(),
          companyName: Yup.string().uppercase().required().trim(),
        })
      )
      .required("Preencha pelo menos um CNPJ"),
  }),

});

export default FormEditUserValidationSchema;
