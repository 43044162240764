import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@material-ui/core";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

export interface BreadcrumbChild {
  link?: string;
  label?: string;
  icon?: React.ReactNode;
  isIcon?: boolean;
}

export interface BreadcrumbProps {
  data?: BreadcrumbChild[];
  isLocation?: boolean;
}

const BreadcrumbCustom: React.FC<BreadcrumbProps> = (props) => {
  const { data, isLocation } = props;
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    link: string
  ) => {
    event.preventDefault();
    history.push(link);
  };

  const breadcrumbs = data?.map((child, idx) => {
    if (idx === data.length - 1 && data.length !== 1) {
      return (
        <Typography
          sx={{ fontWeight: 700, fontSize: { xs: 12, lg: 13 } }}
          key="3"
          color="text.primary"
        >
          {child.label && child.label.toUpperCase()}
        </Typography>
      );
    }
    return (
      <Link
        sx={{ fontWeight: 700, fontSize: 10, cursor: "pointer" }}
        underline="hover"
        key="2"
        color="inherit"
        onClick={(e) =>
          handleClick(
            e as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
            child.link || ""
          )
        }
      >
        {child.isIcon && child.icon}
        {child.label && child.label.toUpperCase()}
      </Link>
    );
  });

  const breadcrumbsWithLocation = data?.map((bread, index) => {
    const breadCurrent = bread.link === location.pathname;
    const breadLabel = bread?.label?.toUpperCase();
    return breadCurrent ? (
      <Typography
        component="div"
        key={index}
        variant="body2"
        sx={{ fontWeight: 700 }}
      >
        {breadLabel}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            height: 0,
          }}
        >
          <div
            style={{
              width: "5%",
              border: `1px solid ${theme.palette.primary.highlightedplus}`,
              borderRadius: 2,
            }}
          />
        </div>
      </Typography>
    ) : (
      <Link
        href={bread.link}
        key={index}
        onClick={(e) => handleClick(e, bread?.link ?? "")}
        underline="hover"
        color="inherit"
        sx={{ opacity: 1 }}
      >
        <Typography variant="body2">{breadLabel}</Typography>
      </Link>
    );
  });

  return (
    <Breadcrumbs
      sx={{ marginLeft: 0.5, marginTop: "20px" }}
      separator={
        <NavigateNextIcon
          sx={{ color: theme.palette.shadesOfDark.light }}
          fontSize="medium"
        />
      }
      aria-label="breadcrumb"
    >
      {isLocation ? breadcrumbsWithLocation : breadcrumbs}
    </Breadcrumbs>
  );
};

export default BreadcrumbCustom;
