import React from "react";
import { EmployeeProvider } from "@pages/User/Employee/context";
import EmployeeView from "@pages/User/Employee/view";

const Employee: React.FC = () => {
  return (
    <EmployeeProvider>
      <EmployeeView />
    </EmployeeProvider>
  );
};

export default Employee;
