import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

const drawerWidth = 268;

const useStylesSidebar = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    containerLogoMenuOpened: {
      width: "100%",
      textAlign: "center",
      marginRight: theme.spacing(1),
    },
    appBar: {
      paddingTop: theme.spacing(0.6),
      margin: 0,
      backgroundColor: theme.palette.background.default,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      boxShadow: "none",
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      width: "60%",
      padding: "2rem",
      height: "10px",
      marginLeft: "10px",
      marginTop: "20px",
    },
    containerButtonMenu: {
      height: "70px",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      borderRight: `1px solid ${theme.palette.borderColor}`,
      backgroundColor: theme.palette.background.default,
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "10px",
      },
      "&::-webkit-scrollbar-track": { width: "1px", height: "10px" },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.customLightGrey,
        opacity: 0.3,
        borderRadius: "0.8rem",
      },
      "&::-webkit-scrollbar-thumb:hover ": {
        background: theme.palette.customDarkGrey,
      },
    },
    drawerClose: {
      border: "none",
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "10px",
      },
      "&::-webkit-scrollbar-track": { width: "1px", height: "10px" },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.customLightGrey,
        opacity: 0.3,
        borderRadius: "0.8rem",
      },
      "&::-webkit-scrollbar-thumb:hover ": {
        background: theme.palette.customDarkGrey,
      },

      width: theme.spacing(4) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(8) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    containerHeaderToolbarOpen: {
      display: "flex",
      alignItems: "center",
      marginTop: "60px",
    },
    avatar: {
      height: "40px",
      width: "40px",
      marginTop: "3.2rem",
      marginBottom: "3.2rem",
      color: theme.palette.avatar.main,
      backgroundColor: theme.palette.avatar.background,
    },
    userName: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "13.6px",
      lineHeight: "20px",
      color: theme.palette.primary.main,
    },
    city: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "15px",
      color: theme.palette.text.secondary,
      opacity: 0.7,
    },
    textMenuItem: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "13.6px",
      lineHeight: "21px",
      color: theme.palette.text.primary,
      opacity: 0.7,
      marginLeft: "25px",
    },
    textMenuItemSelected: {
      fontFamily: "DM Sans",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "13.6px",
      lineHeight: "21px",
      color: theme.palette.primary.main,
      marginLeft: "25px",
    },
    containerAvatar: {
      marginTop: "10px",
      display: "flex",
      marginLeft: "20px",
      alignItems: "center",
      height: "50px",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
    },
    containerAvatarText: {
      display: "block",
      marginLeft: "10px",
    },
    listItem: {
      paddingLeft: 0,
      fontSize: "1.6rem",
      color: theme.palette.getContrastText(theme.palette.background.paper),
      marginBottom: "1.4rem",
      borderRadius: "0.5rem",
      "& img": {
        filter:
          "invert(45%) sepia(0%) saturate(1244%) hue-rotate(218deg) brightness(85%) contrast(92%)",
      },
    },
    divider: {
      margin: "3.2rem 0 1.6rem",
    },
    borderMenu: {
      width: "5px",
      height: "25px",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "8px",
      marginRight: "25px",
    },
    borderMenuNormal: {
      width: "5px",
      height: "25px",
      backgroundColor: "transparent",
      borderRadius: "8px",
      marginRight: "25px",
    },
    listItemSelected: {
      fontWeight: 500,
      fontSize: "16px",
      backgroundColor: `transparent !important`,
      color: theme.palette.primary.main,
      "& svg": { color: theme.palette.primary.main },
      "& img": {
        filter:
          "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
      },
    },
    listItemHover: {
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primaryLight.main,
        "& p": {
          color: theme.palette.primary.main,
        },
        "& img": {
          filter:
            "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
        },
        "& svg": {
          color: theme.palette.primary.main,
          filter:
            "invert(17%) sepia(47%) saturate(5421%) hue-rotate(350deg) brightness(88%) contrast(110%)",
        },
      },
    },
    dividerStyle: {
      backgroundColor: theme.palette.borderColor,
      marginTop: theme.spacing(0.6),
    },
    icon: {
      fontSize: "22px",
    },
  })
);

export default useStylesSidebar;
