import Modal from "@components/Modal";
import { useHomeUserContext } from "@context/HomeUserContext";
import { useUserState } from "@context/UserContext";
import {
  CircularProgress,
  createStyles,
  FormControl,
  InputAdornment,
  makeStyles,
  TextField,
  useTheme,
} from "@material-ui/core";
import {
  MappedDiscount,
  PaymentMethod,
} from "@modules/discount/model/IDiscountService";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { formatCurrency } from "@utils/index";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { formatNumberToCurrency } from "../PerformanceManagement/UtilsPerfomanceManagement/utilsPerformanceManagment";
import { useDiscountContext } from "./DiscountContext";

interface ModalDiscountProps {
  data: {
    open: boolean;
    discount: ModalData | null;
  };
  onClose: () => void;
}

export interface ModalData extends MappedDiscount {
  description: string;
  value: string;
  productId: string;
}

const commonSx = {
  fontWeight: 700,
  textAlign: "start",
};

const ModalDiscount: React.FC<ModalDiscountProps> = ({ onClose, data }) => {
  const classes = useStyles();
  const userState = useUserState();
  const {
    setSelectedCNPJ,
    handleSave,
    getDiscounts,
    isLoadingSaveDiscount,
  } = useDiscountContext();
  const { documentSelected } = useHomeUserContext();
  const [newDiscount, setNewDiscount] = useState(0);
  const { discount } = data;
  const theme = useTheme();

  useEffect(() => {
    if (data.open) {
      setNewDiscount(Number(data.discount?.discount) * 100 || 0);
    }
  }, [data, setNewDiscount]);

  const numericValue = (event) => {
    const inputValue = event.target.value.replace(/\D/g, "");
    const numericValue = inputValue === "" ? 0 : Number(inputValue);
    return numericValue;
  };

  const handleOnChange = (event) => {
    setNewDiscount(numericValue(event));
  };

  const saveDiscount = useCallback(async () => {
    if (data.discount) {
      const disc = {
        productId: data.discount?.productId,
        value: newDiscount / 100,
        paymentMethod: data.discount?.paymentMethod,
        userId: userState.userID,
      };

      const response = await handleSave(disc);
      if (response) {
        onClose();
        getDiscounts();
      } else {
        console.log("Failed to save discount.");
      }
    }
  }, [
    data.discount,
    getDiscounts,
    handleSave,
    newDiscount,
    onClose,
    userState.userID,
  ]);

  const descriptionsTypography = [
    {
      label: "Posto:",
      value: documentSelected.label,
    },
    {
      label: "Produto:",
      value: discount?.description,
    },
    {
      label: "Método de pagamento:",
      value: PaymentMethod[String(discount?.paymentMethod)],
    },
    {
      label: "Desconto atual:",
      value: formatCurrency(Number(discount?.value)),
    },
  ];

  const disabled = useMemo(() => {
    const receivedDiscount = data.discount?.discount;
    const value = newDiscount / 100;
    return (
      (receivedDiscount === value && value !== 0) || receivedDiscount === value
    );
  }, [data.discount?.discount, newDiscount]);

  const initCNPJ = useCallback(() => setSelectedCNPJ(documentSelected.cnpj), [
    documentSelected.cnpj,
    setSelectedCNPJ,
  ]);

  useEffect(() => {
    initCNPJ();
  }, [initCNPJ]);

  return (
    <Modal
      open={data.open}
      onClose={onClose}
      aria-labelledby="message-title"
      aria-describedby="message-description"
      hiddenButton
    >
      <Box
        sx={{
          px: 1,
          pb: 2,
          backgroundColor: "white",
          margin: "auto",
          width: { xs: "295px", md: "400px" },
          maxHeight: "400px",
          m: 0,
          minWidth: "fit-content",
        }}
      >
        <Grid
          container
          textAlign={"center"}
          justifyContent={"center"}
          gap={2.5}
        >
          <Grid item xs={12}>
            <Typography
              id="message-title"
              variant="h4"
              component="h2"
              sx={commonSx}
            >
              Editar desconto
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {descriptionsTypography.map((items, index) => (
              <Stack key={index} direction="row" gap=".5rem">
                <Typography
                  id="message-description"
                  variant="h5"
                  component="p"
                  sx={commonSx}
                >
                  {items.label}
                </Typography>
                <Typography
                  id="message-description"
                  variant="h5"
                  component="span"
                  sx={{
                    fontWeight: 500,
                    textAlign: "start",
                  }}
                >
                  {items.value}
                </Typography>
              </Stack>
            ))}
          </Grid>
          <FormControl
            fullWidth
            className={classes.formControl}
            variant="outlined"
          >
            <TextField
              className={classes.textfield}
              label="Desconto"
              variant="outlined"
              id="Desconto"
              type="text"
              value={formatNumberToCurrency(newDiscount)}
              onChange={(e) => {
                handleOnChange(e);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Grid item xs={10} sm={10} md={9} lg={7}>
            <Button
              variant="contained"
              fullWidth
              sx={{ fontSize: 15, height: 50 }}
              disabled={disabled}
              onClick={() => saveDiscount()}
              startIcon={
                isLoadingSaveDiscount && (
                  <CircularProgress
                    style={{
                      height: 20,
                      width: 20,
                      color: theme.palette.background.paper,
                    }}
                  />
                )
              }
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default ModalDiscount;

const useStyles = makeStyles(() =>
  createStyles({
    textfield: {
      width: "100%",
      fontFamily: "Montserrat",
      "& label": {
        color: "#3E3D3D",
        fontWeight: 400,
        fontSize: "1.6rem",
        borderRadius: "8px",
        opacity: "0.6",
      },
      "& .MuiInputLabel-shrink": {
        fontSize: "1.6rem",
        fontWeight: 700,
        color: "#3E3D3D",
        opacity: 1,
        backgroundColor: "#fff",
        paddingRight: 6,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        opacity: "0.6",
      },
    },
    formControl: {
      borderRadius: "8px",
      height: "56px",
      width: "100%",
    },
  })
);
