import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import { useNozzle } from "@pages/User/Nozzle/context";
import ModalNozzle from "@pages/User/Nozzle/resource/ModalNozzle";
import TableNozzle from "@pages/User/Nozzle/resource/TableNozzle";
import ButtonDefault from "@components/ButtonDefault";
import SkeletonTableInvoicesImages from "@pages/Admin/ListInvoicesImages/SkeletonTableListInvoicesImages";
import { BreadcrumbChild } from "@components/BreadcrumbCustom";
import BreadcrumbAndTitle from "@components/BreadcrumbAndTitle";
import ROUTES from "@config/routes";
import Layout from "@components/Layout";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";

const NozzleView: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const {
    nozzles,
    loading,
    selectedCNPJ,
    nozzlesSelectList,
    handleOnClickFetchNozzles,
    handleOnClickUpdateImage,
    handleOnClickSendImage,
    loadingFetchNozzles,
  } = useNozzle();

  const breadcrumbDataOrders: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.HOME,
      label: "Início",
    },
    {
      label: "Bico e produto",
    },
  ];

  return (
    <Layout>
      <Box
        sx={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <BreadcrumbAndTitle
          breadcrumbData={breadcrumbDataOrders}
          title="Bico e produto"
        />
      </Box>
      <Box
        sx={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Grid item xs={12}>
          {nozzles.length === 0 && !loadingFetchNozzles ? (
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  textAlign: "center",
                  padding: 0,
                  marginTop: 48,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!loadingFetchNozzles && (
                  <ButtonDefault onClick={handleOnClickFetchNozzles}>
                    Solicitar registros de bicos
                  </ButtonDefault>
                )}
              </Grid>
            </Grid>
          ) : loadingFetchNozzles ? (
            <Grid item xs={12}>
              <SkeletonTableInvoicesImages cols={5} rows={10} />
            </Grid>
          ) : (
            <Grid container>
              <Grid item xs={12} style={{ textAlign: "center", padding: 0 }}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <ModalNozzle
                    handleNozzlesFirstRegister={handleOnClickSendImage}
                    cnpj={selectedCNPJ.cnpj}
                    nozzlesModal={nozzlesSelectList}
                    labelButton="Enviar fotos"
                    loading={loading}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <TableNozzle
                  handleNozzlesUpdate={handleOnClickUpdateImage}
                  rows={nozzles}
                  rowsPerPage={10}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Layout>
  );
};

export default NozzleView;
