import { addDays } from "date-fns";

export type Filial =
  | { id: "C001"; name: "Manaus" }
  | { id: "C002"; name: "Cruzeiro do Sul" }
  | { id: "C003"; name: "Porto Velho" }
  | { id: "C004"; name: "Vilhena" }
  | { id: "C005"; name: "Várzea Grande" }
  | { id: "C006"; name: "Rio Branco" }
  | { id: "C008"; name: "Itaituba" }
  | { id: "C009"; name: "Belém" }
  | { id: "C010"; name: "Paranaguá" }
  | { id: "C011"; name: "Santarém" }
  | { id: "C012"; name: "Sinop" }
  | { id: "C013"; name: "Caracaraí" }
  | { id: "C080"; name: "Campo Grande 1" }
  | { id: "C081"; name: "Campo Grande 2" }
  | { id: "C082"; name: "Paulínia" };

export const FilialOptionsList = [
  { value: "C001", label: "Manaus" },
  { value: "C002", label: "Cruzeiro do Sul" },
  { value: "C003", label: "Porto Velho" },
  { value: "C004", label: "Vilhena" },
  // { value: "C005", label: "Várzea Grande" },
  // { value: "C006", label: "Rio Branco" },
  { value: "C008", label: "Itaituba" },
  // { value: "C009", label: "Belém" },
  { value: "C009", label: "Belém" },
  // { value: "C010", label: "Paranaguá" },
  { value: "C011", label: "Santarém" },
  { value: "C012", label: "Sinop" },
  // { value: "C013", label: "Caracaraí" },
  // { value: "C080", label: "Campo Grande 1" },
  // { value: "C081", label: "Campo Grande 2" },
  // { value: "C082", label: "Paulínia" },
  // -------------------------------------
  { value: "CDM5", label: "Vázea Grande" },
  { value: "CD16", label: "Porto Nacional" },
  { value: "CD17", label: "São Luís" },
  { value: "CD18", label: "Paulínia" },
  { value: "CD19", label: "Senador Canedo" },
  { value: "CD10", label: "Araucária" },
  { value: "CD14", label: "Campo Grande" },
  { value: "CD21", label: "Itajaí" },
  { value: "CD23", label: "Sarandi" },
];

const today = new Date();
export const minDate = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() + 1
);
export const dayPlusOne = addDays(new Date(), 0);

export const filialLabel = (value: string) => {
  return (
    FilialOptionsList.find((f) => f.value === value)?.label || "Não informado"
  );
};

export type FreightType = "CIF" | "FOB";

export interface WithdrawBasis {
  id: string;
  withdrawBasis: string;
  /**
   * Nome da base de retirada
   */
  name: string;

  branch: string;
  branchName: string;

  filialID: string;

  shippingPoint: string;

  timeZone: string;
}

export interface Price {
  customerID: string;

  transportationZone: string;

  productID: string;

  companyID: string;

  filialID: string;

  payCond: string;

  price: number;

  freight: number;

  unity: string;
}

export interface IProductQuantity extends IProduct {
  quantity: number | null;
  deadlinePayment: number;
  price: number | null;
  hashPrice: string;
}

export interface IProduct {
  id: string;
  description: string;
  payCond: number;
}
export interface IWithdrawalBasis {
  name: string;
  id: string;
}

export interface IAddress {
  freightType: string;
  id: string;
  street: string;
  city: string;
  UF: string;
  postalCode: string;
  phone: string;
  email: string;
  district: string;
  /**
   * Usado na função de calcular preço de produto
   * Chave do endereço (o frete do CEP selecionado)
   */
  transportationZone: string;
  /**
   * Filial padrão para o endereço selecionado
   */
  filialIdDefault: string;
  /**
   * Base de retirada padrão para o endereço selecionado (Centro de Distribuição)
   */
  withdrawBasisIdDefault: string;
}

export interface IClient {
  CNPJ: string;

  id: string;

  name: string;

  /**
   * Esconde o caso de uso de criar pedido
   */
  hiddePrice: boolean;

  /**
   * Tipo de frete
   */
  freightType: FreightType;

  /**
   * Prazo máximo em dias para pagamento
   */
  payCond: number;

  /**
   * ID da empresa do grupo dentro do SAP
   * (RodoAmazonia, ATEM, etc)
   */
  companyID: string;

  DistributionChannel: string;

  Division: string;

  /**
   * Responsável por atender os pedidos do CNPJ
   */
  accessor: {
    id: string;
    name: string;
  };

  segment: {
    id: string;
    /**
     * Segmento de atuação do CNPJ selecionado
     * (energia, consumidor final, posto atem, posto marítimo, posto bandeira branca, etc)
     */
    description: string;
  };

  address: IAddress[];

  products: IProduct[];

  withdrawBasis: WithdrawBasis[];
}

export interface IUser {
  company: IClient[];
}

export interface IFormRequestValues {
  CNPJ: string;
  address: IAddress | null;
  freightType: "CIF" | "FOB";
  deliveryDate: Date;
  comments: string;
  products: IProductQuantity[];
  withdrawBasisID: string;
  filialID: string;
  productSelected: string;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  enabled: boolean;
  kind: string;
  attributes: {
    BPID: string;
    INTERNAL_NAME: string;
    CNPJ: {
      companyName: string;
      CNPJ: string;
    }[];
  };

}

export interface IHistoryUserRegister {
  dt: string;
  count: string;
}
export interface IHistoryTotalQtVolLT {
  dt: string;
  sum: number;
}
export interface IHistoryTotalQtVolLTPerProduct {
  product: string;
  quantityPosh: number;
  quantityAtem: number;
  rate: number;
}
export interface IDetailsInstantRewardPosh {
  lastStateUpdate: string;
  user: {
    name: string;
    document: string;
    email: string;
    phone: string;
  };
  prize_id: string;
  prize_reward: string;
  company: {
    id: string;
    name: string;
    document: string;
  };
  taxReceipt: {
    issue_date: string;
    issue_time: string;
    nfKey: string;
    original_resource: string;
  };
}

export interface IAuthenticatedUser {
  sub: string;
  firstName: string;
  email: string;
  fullName: string;
  listCNPJ?: {
    CNPJ: string;
    companyName: string;
  }[];
  roles: string[];
  adminMode?: boolean;
  token: string;
  refreshToken: string;
}

export const products = [
  { product: "GASC" },
  { product: "GAA" },
  { product: "ODM" },
  { product: "ODMB" },
  { product: "OS10" },
  { product: "OB10" },
  { product: "AEHC" },
  { product: "AEAC" },
  { product: "B100" },
  { product: "ODMR" },
];

export interface SimulationProduct {
  id: string;
  description: string;
  quantity?: number;
  compartment?: string;
  arrows?: number[];
  uuid?: string;
  current_arrow?: number;
}

export interface IFormCreateOrderService {
  id_erp: string;
  placa: string;
  products: SimulationProduct[];
  productSelected: string;
  user_name: string;
  user_document: string;
  reason: string;
  comment?: string;
  source: "MANUAL";
  CNPJ?: string;
}

export interface IFormSimulateOrder {
  CNPJ?: string;
  products: SimulationProduct[];
  placa: string;
  productSelected: string;
}

export interface IPaymentDetails {
  deadlinePayment: number;
  payment: string;
}

export interface IPaymentSchedule {
  [key: number]: IPaymentDetails;
}
