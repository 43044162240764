export const ProductGroupToProductID = {
  ANIDRO: "10045,10001400,10045EMP,10045BRD",
  ARLA: "10500",
  BIODIESEL: "10295,10295EMP,10295BRD,10007549",
  "DIESEL MARITIMO": "10277,10277EMP",
  "DIESEL S10": "10460,10460EMP,10460AEN,10460BRD,10474,10474BRD,10475,10488",
  "DIESEL S1800": "10028,10028AEN,10352,10353",
  "DIESEL S500":
    "10093,10093EMP,10093BRD,10093AEN,10094,10335,10335BRD,10336,10337,10374,10001830",
  GASOLINA: "10031,10031AEN,10031EMP,10031BRD,10076,10076BRD,10122,20000010",
  GNV: "10200",
  HIDRATADO: "10059,10059EMP,10001399",
  "GAS CONDENSADO": "10501,10501AEN",
} as Record<string, string>;

export const products = [
  { value: "10045", label: "Etanol Hidratado" },
  { value: "10500", label: "Biodiesel" },
  { value: "10460", label: "Diesel s10" },
  { value: "10028", label: "Diesel s1800" },
  { value: "10093", label: "Diesel s500" },
  { value: "10031", label: "Gasolina Comum" },
  { value: "10076", label: "Gasolina Aditivada" },
  { value: "10200", label: "GNV" },
];

export const productList = [
  "Gasolina Comum",
  "Gasolina Aditivada",
  "Diesel S10",
  "Diesel S500",
  "GNV",
  "Etanol Hidratado",
];

export const unityList = ["L", "M3", "UN"];
