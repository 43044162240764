import DropdownOutlined from "@components/DropdownOutlined";
import { CNPJS } from "@context/auth/IAuthContext";
import { useUserState } from "@context/UserContext";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import { capitalizeFirstLetterFullString, maskCNPJ } from "@utils/index";
import { productList, unityList } from "@utils/product";
import { Form, FormikProps } from "formik";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formTitle: {
      fontWeight: "bold",
      marginBottom: "2rem",
      marginTop: "1rem",
      color: "#3E3D3D",
    },
    textfield: {
      width: "100%",
      fontFamily: "Montserrat",
      "& label": {
        color: "#3E3D3D",
        fontWeight: 400,
        fontSize: "1.6rem",
        borderRadius: "8px",
        opacity: "0.6",
      },
      "& .MuiInputLabel-shrink": {
        fontSize: "1.6rem",
        fontWeight: 700,
        color: "#3E3D3D",
        opacity: 1,
        backgroundColor: "#fff",
        paddingRight: 6,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        opacity: "0.6",
      },
    },
    styleButtonContained: {
      borderRadius: "2px",
      textTransform: "none",
      fontWeight: "bold",
      background: "#D91F05",
      boxShadow: "none",
      "&:hover": {
        background: "#D91F31",
      },
    },
  })
);

export interface IFormValues {
  id: string;
  CNPJ: {
    companyName: string;
    CNPJ: string;
  };
  product: string;
  unity: string;
  code: string;
}

interface RegistrationFormProps extends FormikProps<IFormValues> {
  isSubmiting?: boolean;
  isEditing?: boolean;
  registeredProducts?: string[];
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  isSubmiting,
  isEditing = false,
  registeredProducts = [],
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const userState = useUserState();

  return (
    <Form>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <Typography
            variant={isMobile ? "subtitle1" : "h5"}
            align="left"
            className={classes.formTitle}
          >
            Cadastrar produto
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DropdownOutlined
            label="Razão social"
            value={values.CNPJ}
            options={userState.listCNPJ}
            getItemLabel={(value: CNPJS) =>
              capitalizeFirstLetterFullString(value.companyName)
            }
            getItemSubtitle={(value: CNPJS) => maskCNPJ(value.CNPJ)}
            onBlur={() => setFieldTouched("CNPJ", true)}
            onChange={(value) => setFieldValue("CNPJ", value)}
            disabled={true}
            error={!!errors.CNPJ && !!touched.CNPJ}
            messageError={!!touched.CNPJ?.CNPJ ? errors.CNPJ?.CNPJ : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <DropdownOutlined
            label="Produto"
            value={values.product}
            options={productList}
            getItemLabel={(value: string) => value}
            onBlur={() => setFieldTouched("product", true)}
            onChange={(value) => {
              setFieldValue("product", value);
            }}
            error={!!errors.product && !!touched.product}
            messageError={!!touched.product ? errors.product : ""}
            disableItem={(product) =>
              registeredProducts.some((item) => item === product)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <DropdownOutlined
            label="Unidade"
            value={values.unity}
            options={unityList}
            getItemLabel={(value: string) => value}
            onBlur={() => setFieldTouched("unity", true)}
            onChange={(value) => {
              setFieldValue("unity", value);
            }}
            error={!!errors.unity && !!touched.unity}
            messageError={!!touched.unity ? errors.unity : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            className={classes.textfield}
            label="Código do PDV"
            variant="outlined"
            id="code"
            type="number"
            value={values.code}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue("code", event.target.value);
            }}
            onBlur={() => setFieldTouched("code", true)}
            error={!!errors.code && !!touched.code}
          />
          {!!errors.code && !!touched.code && (
            <Typography
              style={{ paddingLeft: "1.4rem" }}
              variant="caption"
              color="error"
            >
              {errors.code}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={!props.isValid}
            className={classes.styleButtonContained}
            startIcon={
              isSubmiting && (
                <CircularProgress
                  style={{
                    height: 20,
                    width: 20,
                    color: theme.palette.background.paper,
                  }}
                />
              )
            }
          >
            {isEditing ? "Atualizar " : "Cadastrar"}
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
};

export default RegistrationForm;
