import Layout from "@components/Layout";
import ROUTES from "@config/routes";
import { useHomeUserContext } from "@context/HomeUserContext";
import { Box, Button, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { DiscountProvider, useDiscountContext } from "./DiscountContext";
import TableDiscount from "./TableDiscount";
import BreadcrumbAndTitle from "@components/BreadcrumbAndTitle";
import { BreadcrumbChild } from "@components/BreadcrumbCustom";

const PageContainer: React.FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { loading } = useDiscountContext();
  const { documentSelected } = useHomeUserContext();

  const breadcrumbDataOrders: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.HOME,
      label: "Início",
    },
    {
      label: "Descontos",
    },
  ];

  const commonStyle: any = {
    fontFamily: "Montserrat",
    borderRadius: "2px",
    fontWeight: "bold",
    textTransform: "none",
    background: theme.palette.primary.main,
    color: theme.palette.text.hint,
    padding: isMobile ? "10px 30px" : "10px 30px",
    boxShadow: "none",
    fontSize: isMobile ? "12px" : "",
    height: "56px",
  };

  return (
    <Layout>
      <Box
        style={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <BreadcrumbAndTitle
          breadcrumbData={breadcrumbDataOrders}
          title="Descontos"
        />
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "end",
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Button
          type="submit"
          style={commonStyle}
          onClick={() => history.push(ROUTES.USER_ROUTES.PRODUCT)}
        >
          Ir para produtos
        </Button>
      </Box>

      <Box
        style={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <TableDiscount
          isLoading={loading}
          companyName={documentSelected.label}
        />
      </Box>
    </Layout>
  );
};

const Discount: React.FC = () => {
  return (
    <DiscountProvider>
      <PageContainer />
    </DiscountProvider>
  );
};

Discount.displayName = "Discount";

export default Discount;
