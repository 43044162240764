import {
  createStyles,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { startOfMonth } from "date-fns";
import { endOfMonth } from "date-fns/esm";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import Layout from "../../../components/Layout";
import Title from "../../../components/Title";
import { useIoCContext } from "../../../context/IoCContext/IoCContext";
import { Types } from "../../../ioc/types";
import { IQueryOrdersService } from "../../../modules/orders/models/IQueryOrdersService";
import FormQuery from "./FormQuery";
import { FormQueryProviderTemporary, useFormQuery } from "./FormQueryContext";
import FormQueryValidationSchema from "./FormQueryValidationSchema";
import { IQuery } from "./interface";
import TableOrders from "./TableOrders";

const useStyles = makeStyles((theme) =>
  createStyles({
    formTitle: {
      fontWeight: "bold",
      marginBottom: "2rem",
    },
  })
);

const MyOrdersTemporary: React.FC = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const formQueryContext = useFormQuery();
  const iocContext = useIoCContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const initialValues: IQuery = {
    CNPJ: [],
    filterBy: "deliveryDate",
    financialStatus: "all",
    logisticStatus: "all",
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
  };

  return (
    <Layout>
      <Title title="Meus Pedidos" />
      <div
        style={{
          margin: isMobile ? 0 : "0 7rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={async (values) => {
            try {
              formQueryContext.setLoadingQuery(true);

              const queryOrdersService = iocContext.serviceContainer.get<
                IQueryOrdersService
              >(Types.Orders.IQueryOrdersService);

              const casted = FormQueryValidationSchema.cast(values);
              if (!casted) return;

              const queryResult = await queryOrdersService.executeTemporary({
                CNPJ: casted.CNPJ as string[],
                beginDate: casted.startDate,
                endDate: casted.endDate,
                filterBy: casted.filterBy,
                financialStatus: casted.financialStatus,
                logisticStatus: casted.logisticStatus,
              });
              formQueryContext.setDataQuery(queryResult);
            } catch (error) {
              enqueueSnackbar("Ocorreu um erro ao buscar pedidos", {
                variant: "error",
              });
            } finally {
              formQueryContext.setLoadingQuery(false);
              formQueryContext.setSearchFirstTime(false);
            }
          }}
          validationSchema={FormQueryValidationSchema}
        >
          <Form>
            <Typography
              variant={isMobile ? "subtitle1" : "h5"}
              align="center"
              className={classes.formTitle}
            >
              Buscar Pedidos
            </Typography>
            <FormQuery />
            {formQueryContext.searchFirstTime ? (
              <Typography
                style={{ marginTop: "3rem" }}
                variant={isMobile ? "subtitle1" : "h4"}
                align="center"
              >
                Faça uma busca para ver seus pedidos
              </Typography>
            ) : (
              <TableOrders />
            )}
          </Form>
        </Formik>
      </div>
    </Layout>
  );
};

const Container: React.FC = () => {
  return (
    <FormQueryProviderTemporary>
      <MyOrdersTemporary />
    </FormQueryProviderTemporary>
  );
};

export default Container;
