import { Option } from "@components/TextFieldMultiSelect";
import { getDateInOneWeekTypeDate } from "@utils/index";

export interface IFormikValues {
  customer: Option[];
  scheduleDate: [Date | null, Date | null];
  product: Option[];
  filial: Option[];
  driverName: string;
  plate: string;
}

const from: Date = getDateInOneWeekTypeDate(new Date(), "backward");
const to: Date = getDateInOneWeekTypeDate(new Date(), "forward");


export const initialValuesFormikDefault: IFormikValues = {
  customer: [],
  scheduleDate: [from, to],
  product: [],
  filial: [],
  driverName: "",
  plate:"",
 
  
};

export enum VALUESNAME {
  CUSTOMER = "customer",
  SCHEDULEDATE = "scheduleDate",
  PRODUCT = "product",
  FILIAL = "filial",
  PLATE = "plate",
  DRIVER = "driver",
}

export const optionsList = (roadLoadFilterOptions): Option[] => {
  return (
    roadLoadFilterOptions?.map((item) => {
      return {
        value: item.id,
        label: item.desc,
      };
    }) ?? []
  );
};
