import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { ICreateUserDTO } from "@modules/user/dtos/ICreateUserDTO";
import { ICreateUserService } from "@modules/user/models/ICreateUserService";
import AppError from "@utils/AppError";
import { Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../config/routes";
import FormAddUser from "./Form";
import FormAddUserValidationSchema from "./FormAddUserValidationSchema";
import IFormAddUser from "./IFormAddUser";

const FormAddUserContainer: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const iocContext = useIoCContext();

  const createUserService = iocContext.serviceContainer.get<ICreateUserService>(
    Types.Users.ICreateUserService
  );

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const initialValues: IFormAddUser = {
    firstName: "",
    lastName: "",
    email: "",
    enabled: true,
    emailVerified: false,
    companyName: "",
    CNPJ: "",
    attributes: {
      INTERNAL_NAME: "",
      BPID: "",
      CNPJ: [],
    },
    profile: "",
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values) => {
          try {
            setLoading(true);
            delete values.companyName;
            delete values.CNPJ;

            const casted = FormAddUserValidationSchema.cast(values);

            if (!casted) return;

            const { message } = await createUserService.execute(
              casted as ICreateUserDTO
            );

            enqueueSnackbar(message, {
              variant: "success",
            });

            history.push(ROUTES.ADMIN_ROUTES.HOME);
          } catch (e) {
            if (e instanceof AppError) {
              return enqueueSnackbar(e.message, {
                variant: e.variant,
              });
            }

            enqueueSnackbar("Ocorreu um erro ao cadastrar usuário", {
              variant: "error",
            });
          } finally {
            setLoading(false);
          }
        }}
        validationSchema={FormAddUserValidationSchema}
      >
        <Form style={{ margin: isMobile ? "0.9rem" : "2rem" }}>
          <FormAddUser loading={loading} />
        </Form>
      </Formik>
    </div>
  );
};

export default FormAddUserContainer;
