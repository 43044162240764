import React, { useEffect, useState } from "react";

import { Box, Grid, Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";

import { IDocument, useHomeUserContext } from "@context/HomeUserContext";

import Dropdown from "@components/Dropdown";
import { capitalizeFirstLetterFullString, maskCNPJ } from "@utils/index";
import {
  addToLocalStorageCNPJ,
  getCNPJinLocalStorage,
} from "@utils/localStorageUtils";
import SkeletonHeader from "./SkeletonHeader/SkeletonHeader";
import Notification from "@components/Notification";

const marginLeftDefault = "2.4%";

const Header: React.FC<{
  isInvisibleToolbarCNPJ?: boolean;
  isInvisibleToolbarNotifications?: boolean;
}> = ({
  isInvisibleToolbarCNPJ = false,
  isInvisibleToolbarNotifications = false,
}) => {
  const classes = useStyles();
  const {
    documentList,
    setDocumentList,
    handleFecthCNPJDetails,
    CNPJDetailsLoad,
    CNPJDetails,
  } = useHomeUserContext();

  const SELECTED_CNPJ_STORAGE = getCNPJinLocalStorage("cnpjSelected");
  const isCnpjStorageAvaible = documentList.some(
    (item) => item.cnpj === SELECTED_CNPJ_STORAGE
  );
  const [cnpjSelected, setCNPJSelected] = useState(
    SELECTED_CNPJ_STORAGE ?? documentList.find((item) => item.isSelected)?.cnpj
  );

  useEffect(() => {
    if (
      cnpjSelected !== documentList.find((item) => item.isSelected)?.cnpj ||
      CNPJDetails === null
    ) {
      const newcnpjSelected = documentList.find((item) => item.isSelected)
        ?.cnpj;
      setCNPJSelected(newcnpjSelected ?? documentList[0]?.cnpj);
      handleFecthCNPJDetails(newcnpjSelected ?? documentList[0]?.cnpj);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentList]);

  const getSelectedOption = (): IDocument | undefined => {
    let selected: IDocument | undefined = undefined;
    // Se tem CNPJ no localstorage e esse cnpj está habilitado para o usuário nas permimssões então seta o o cnpj no localstorage
    if (SELECTED_CNPJ_STORAGE && isCnpjStorageAvaible) {
      selected = documentList.find(
        (item) => item.cnpj === SELECTED_CNPJ_STORAGE
      );
    } else {
      // Se o CNPJ do storage não estiver disponível, retorne o primeiro item
      selected = documentList[0];
      addToLocalStorageCNPJ(
        "cnpjSelected",
        documentList.map((count) => count.cnpj)[0]
      );
    }

    return selected;
  };

  return (
    <>
      <Box className={classes.containerMain} sx={{ border: "0px solid red" }}>
        <Grid container style={{ justifyContent: "right", gap: 10 }}>
          {/* NOTE: Notificações */}
          <Grid item>
            {!isInvisibleToolbarNotifications && <Notification />}
          </Grid>

          {/* NOTE: CNPJ */}
          <Grid item style={{ border: "0px solid red", textAlign: "right" }}>
            {!isInvisibleToolbarCNPJ && (
              <>
                {CNPJDetailsLoad ? (
                  <SkeletonHeader />
                ) : (
                  <Dropdown
                    option={getSelectedOption()}
                    options={documentList}
                    onChange={(option) => {
                      if (isCnpjStorageAvaible) {
                        addToLocalStorageCNPJ("cnpjSelected", option?.cnpj);
                      }
                      const newDocumentList = documentList.map((item) => ({
                        ...item,
                        isSelected: option === item,
                      }));
                      setDocumentList(newDocumentList);
                    }}
                    getHeaderLabel={(option) =>
                      capitalizeFirstLetterFullString(
                        option?.label?.toLowerCase()
                      )
                    }
                    getSelectItemLabel={(option) =>
                      capitalizeFirstLetterFullString(
                        option?.label.toLowerCase()
                      )
                    }
                    getHeaderSubLabel={(option) => maskCNPJ(option?.cnpj)}
                    getSelectItemSubLabel={(option) => maskCNPJ(option?.cnpj)}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Header;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerMain: {
      maxWidth: "98.6%",
      width: "98.6%",
      marginLeft: marginLeftDefault,
      display: "flex",
      marginRight: 0,
      paddingTop: theme.spacing(0.6),
    },
  })
);
