// eslint-disable-next-line import/no-anonymous-default-export
export default {
  host:
    window.location.hostname === "localhost"
      ? "localhost"
      : `.${window.location.hostname.replace(/^\w+\./, "")}`,
  api: {
    url: {
      production: "https://middlewarecliente.grupoatem.com.br/rest",
      homologation: "http://localhost:8081/rest",
      development: "https://middlewarecliente-dev.grupoatem.com.br/rest",
    },
    urlPump: {
      production: "https://pump.grupoatem.com.br/rest/",
    },
    urlCognito: {
      production: "https://gateway.grupoatem.com.br",
      homologation: "https://gateway-dev.grupoatem.com.br",
      development: "https://gateway-dev.grupoatem.com.br",
    },
    timeout: 300000,
  },
  cognito: {
    gateway: {
      production: "https://gateway.grupoatem.com.br",
      homologation: "http://localhost:8080TESTE",
      development: "https://gateway-dev.grupoatem.com.br",
    },
    urlLogout: {
      development:
        "http://localhost:3000/#/logout?url=http://localhost:3001&rules=portal_cliente",
      homologation:
        "https://authcog-dev.grupoatem.com.br/#/logout?url=https://portal-dev.grupoatem.com.br&rules=portal_cliente",
      production:
        "https://authcog.grupoatem.com.br/#/logout?url=https://portal.grupoatem.com.br&rules=portal_cliente",
    },
  },
  keyStorage: {
    newOrderData: "@portal-client:newOrderData",
    auth: { token: "@ATEM:portal-client:token" },
  },
};

export const compartmentsAvailable = [...new Array(12)].map(
  (_, i) => `${i + 1}`
); // array from 1 to 1

console.log(`Running using ENV=${process.env.REACT_APP_ENVIRONMENT}`);

export const isDevelopment =
  process.env.REACT_APP_ENVIRONMENT === "development" ||
  Boolean(window.location.origin.split(".")[0].match(/-dev$/));

export const nameLogin = localStorage.getItem("nameLogin");
