import React, { useState, useEffect } from "react";
import { Form, Formik, useFormikContext } from "formik";
import { Grid } from "@mui/material";

import { ReclamationUserProvider } from "@context/ReclamationContext/ReclamationUserContext";
import PaginationDynamic from "@components/PaginationDynamic/PaginationDynamic";
import SkeletonTable from "@components/SkeletonTable/SkeletonTable";
import Layout from "@components/Layout";

import TableReclamationIndex from "./Sections/TableReclamationIndex";
import OpenNewReclamation from "./Sections/OpenNewReclamation";

import {
  ITEMS_PER_PAGE,
  ReclamationConsultProvider,
  useReclamationConsultContext,
} from "./context/ReclamationConsultContext";
import ValidationFilterReclamation from "./form/validation.yup";
import { initialValuesFomik } from "./form/initial.values";
import { IReclamationFormValues } from "./form/interface";
import { ReclamationForm } from "./form/ReclamationForm";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";

interface IReclamationConsultBody {
  currentPage?: number;
  setCurrentPage?: (page: number) => void;
}

const ReclamationConsultBody: React.FC<IReclamationConsultBody> = (props) => {
  const { currentPage, setCurrentPage } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const { values } = useFormikContext();
  const {
    reclamation,
    reclamationUserContext,
  } = useReclamationConsultContext();
  const {
    totalCount,
    tabActive,
    loadingDataFilterReclamation,
  } = reclamationUserContext;

  const handlePageChange = async (newPage: number) => {
    setCurrentPage && setCurrentPage(newPage + 1);
    await reclamation?.fetch.consult(values, newPage + 1);
  };

  useEffect(() => {
    const fetch = async () => {
      await reclamation?.fetch.consult(values, 0);
      setCurrentPage && setCurrentPage(1);
    };
    fetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabActive]);

  return (
    <Layout disableToolbar={true}>
      <Box
        style={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <OpenNewReclamation />
      </Box>
      <Box
        style={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Grid item xl={12}>
          <ReclamationForm />

          {loadingDataFilterReclamation ? (
            <SkeletonTable rows={6} cols={8} style={{ display: "contents" }} />
          ) : (
            <Grid container sx={{ gap: 2, p: 2, my: 2 }}>
              <Grid item xs={12}>
                <TableReclamationIndex />
              </Grid>
              <Grid item xs={12}>
                <Grid container style={{ paddingTop: "20px" }}>
                  <Grid item xs={12} sx={{ p: 1 }}>
                    <PaginationDynamic
                      onPageChange={(_, page) => handlePageChange(page)}
                      showFirstButton={true}
                      showLastButton={true}
                      showMilestones={true}
                      page={currentPage}
                      itemsPerPage={ITEMS_PER_PAGE}
                      totalItems={totalCount}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </Layout>
  );
};

const ReclamationConsultForm: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const { reclamation } = useReclamationConsultContext();

  const [currentPage, setCurrentPage] = useState(1);

  const handleSubmit = async (
    values: IReclamationFormValues,
    page?: number
  ) => {
    await reclamation?.fetch.consult(values, page);
    if (page !== undefined) setCurrentPage(page);
  };

  return (
    <Formik
      initialValues={initialValuesFomik}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={ValidationFilterReclamation}
    >
      <Form>
        {React.isValidElement(children) &&
          React.cloneElement(children, {
            currentPage,
            setCurrentPage,
          } as React.Attributes)}
      </Form>
    </Formik>
  );
};

const ReclamationConsult: React.FC = () => {
  return (
    <ReclamationUserProvider>
      <ReclamationConsultProvider>
        <ReclamationConsultForm>
          <ReclamationConsultBody />
        </ReclamationConsultForm>
      </ReclamationConsultProvider>
    </ReclamationUserProvider>
  );
};

export default ReclamationConsult;
