import { isValidCPFOrCPNJ } from "@utils/index";
import { useCallback } from "react";
import useDialogAlert from "../../../hooks/useDialogAlert";

const useAddCNPJ = (values, setFieldValue) => {
  const { snackbar } = useDialogAlert();

  const addCNPJ = useCallback(() => {
    if (!values.CNPJ || !values.companyName) return;

    const CNPJNotMasked = values.CNPJ.replace(/\D/g, "");

    if (!isValidCPFOrCPNJ(CNPJNotMasked)) return;

    const isCNPJExists = values.attributes.CNPJ.some(
      (item) => item.CNPJ === CNPJNotMasked
    );

    const description = CNPJNotMasked.length === 14 ? "CNPJ" : "CPF";

    if (isCNPJExists) {
      snackbar({
        message: `O ${description} já foi adicionado, selecione outro`,
        variant: "error",
      });
      return;
    }

    const data = {
      CNPJ: CNPJNotMasked,
      companyName: values.companyName,
    };

    const newCNPJ = [...values.attributes.CNPJ, data];
    setFieldValue("attributes.CNPJ", newCNPJ);
    setFieldValue("CNPJ", "");
    setFieldValue("companyName", "");
  }, [values, setFieldValue, snackbar]);

  return addCNPJ;
};

export default useAddCNPJ;
