import { CloseIcon } from "@components/Icons";
import { useHomeUserContext } from "@context/HomeUserContext";
import { Box, Button, Drawer, Grid } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IDiscountProduct } from "@modules/product/dtos/IProductResponse";
import { ISaveProductDTO } from "@modules/product/dtos/ISaveProductDTO";
import { Formik } from "formik";
import React from "react";
import * as yup from "yup";
import { useDiscountProductContext } from "../context/ProductContext";
import RegistrationForm, { IFormValues } from "./RegistrationForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerBox: {
      width: "450px !important",
      padding: "1rem 5rem",
    },
    buttonGrid: {
      display: "flex",
      justifyContent: "end",
    },
    button: {
      justifyContent: "end",
      width: "fit-content",
      border: "0px solid red",
    },
  })
);

type Anchor = "top" | "left" | "bottom" | "right";

interface SaveProductDrawerProps {
  anchortype: Anchor;
  open?: boolean;
  handleClose?: () => void;
  product?: IDiscountProduct;
}

const SaveProductDrawer: React.FC<SaveProductDrawerProps> = ({
  anchortype,
  open,
  handleClose,
  product,
}) => {
  const classes = useStyles();
  const {
    saveProduct,
    updateProduct,
    isSaveProductLoading,
    registeredProducts,
  } = useDiscountProductContext();

  const { documentSelected } = useHomeUserContext();

  const isEditing = Boolean(product);

  const validationSchema = yup.object().shape({
    id: yup.string().optional(),
    CNPJ: yup
      .object()
      .shape({
        companyName: yup.string(),
        CNPJ: isEditing
          ? yup.string()
          : yup.string().required("Este campo é obrigatório"),
      })
      .required("Este campo é obrigatório"),
    product: yup.string().required("Este campo é obrigatório"),
    unity: yup.string().required("Este campo é obrigatório"),
    code: yup
      .string()
      .max(6, "É permitido somente até 6 dígitos")
      .required("Este campo é obrigatório"),
  });

  const handleSubmit = (values: IFormValues) => {
    const body: ISaveProductDTO = {
      companyId: isEditing ? values.id : values.CNPJ.CNPJ,
      name: values.product,
      unit: values.unity,
      internal_code: values.code,
    };

    isEditing
      ? updateProduct(body, handleClose)
      : saveProduct(body, handleClose);
  };

  return (
    <React.Fragment>
      <Drawer anchor={anchortype} open={open}>
        <Box className={classes.drawerBox}>
          <Grid container>
            <Grid xs={12} className={classes.buttonGrid}>
              <Button onClick={handleClose} className={classes.button}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
          <Formik
            initialValues={{
              id: product?.id ?? "",
              CNPJ: {
                CNPJ: documentSelected.cnpj ?? "",
                companyName: documentSelected.label ?? "",
              },
              product: product?.name ?? "",
              unity: product?.unit ?? "",
              code: product?.internal_code ?? "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <RegistrationForm
                {...props}
                isSubmiting={isSaveProductLoading}
                isEditing={isEditing}
                registeredProducts={registeredProducts}
              />
            )}
          </Formik>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default SaveProductDrawer;
