import React from "react";
import ViewLoadRoad from "@pages/User/RoadLoad/view";
import { RoadLoadProvider } from "@pages/User/RoadLoad/context/RoadLoadContext";

const RoadLoad = () => {
  return (
    <RoadLoadProvider>
      <ViewLoadRoad />
    </RoadLoadProvider>
  );
};

export default RoadLoad;
