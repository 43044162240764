import React from "react";
import { NozzleProvider } from "@pages/User/Nozzle/context";
import NozzleView from "@pages/User/Nozzle/view";

const Nozzle = () => {
  return (
    <NozzleProvider>
      <NozzleView />
    </NozzleProvider>
  );
};

export default Nozzle;
