import ROUTES from "@config/routes";

export const routesToHide = [
  ROUTES.USER_ROUTES.MY_ORDERS,
  ROUTES.USER_ROUTES.NEW_ORDER,
  ROUTES.USER_ROUTES.APPOINTMENT_TITLE,
  ROUTES.USER_ROUTES.APPOINTMENT_INVOICES,
  ROUTES.USER_ROUTES.APPOINTMENT_PRICE,
  ROUTES.USER_ROUTES.HANDOUTS,
  ROUTES.USER_ROUTES.RECLAMATION,
  ROUTES.USER_ROUTES.DASH_POSH,
  ROUTES.USER_ROUTES.CHANGE_PASSWORD,
  ROUTES.USER_ROUTES.SIMULATE_ORDER,
  ROUTES.ADMIN_ROUTES.NEW_USER,
  ROUTES.ADMIN_ROUTES.LIST_USERS,
  ROUTES.ADMIN_ROUTES.LIST_INVOICES_IMAGES,
  ROUTES.ADMIN_ROUTES.ADD_COMMUNICATION,
  ROUTES.ADMIN_ROUTES.ADD_IMAGES_LOGIN,
  ROUTES.ADMIN_ROUTES.HANDOUTS,
  ROUTES.ADMIN_ROUTES.HOME,
];
