import React, { ReactNode, useEffect, useState } from "react";
import {
  Modal as ModalV5,
  ModalProps,
  Box,
  Grid,
  IconButton,
  Backdrop,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@components/Button";

interface Props extends Omit<ModalProps, "open" | "children"> {
  open?: boolean;
  children: ReactNode;
  ModalButton?: React.ReactElement;
  label?: string;
  onCloseCallback?: () => void;
  hiddenButton?: boolean;
  disableModalButtonOpen?: boolean;
}

const style = {
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  maxWidth: { xs: "95vw", lg: "90vw" },
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 0,
  margim: 0,
  borderRadius: 2,
  border: "0px solid red",
};

const Modal: React.FC<Props> = ({ children, open = false, ...props }) => {
  const {
    label,
    ModalButton,
    onCloseCallback,
    hiddenButton,
    onClose,
    disableModalButtonOpen,
  } = props;

  const [openModal, setOpen] = useState(open);
  const handleOpen = () => !disableModalButtonOpen && setOpen(true);

  useEffect(() => {
    if (typeof open === "undefined") return;
    if (open !== openModal) {
      setOpen(open);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    handleCallBackClose();
    onClose && onClose({}, "backdropClick");
  };

  const handleCallBackClose = () => {
    onCloseCallback && onCloseCallback();
  };

  const ModalButtonWithProps =
    ModalButton &&
    React.cloneElement(ModalButton, {
      onClick: (event) => {
        event.preventDefault();

        const childrenArray = Array.from(event.target.children);

        const hasDisabledChild = childrenArray.some((item) => {
          const element = item as HTMLElement;
          return element?.classList?.contains("Mui-disabled");
        });

        if (hasDisabledChild) {
          event.target.style.cursor = "not-allowed";
          return; // Note: Inibe acao de abrir o modal do componente pai apos clonar o componente filho
        }

        if (typeof ModalButton.props.onClick === "function") {
          ModalButton?.props?.onClick(event);
        }

        return openModal ? handleClose() : handleOpen();
      },
      className: `ModalButtonClone ${props.className}`,
      sx: {
        "& .Mui-disabled": {
          opacity: "0.5",
        },
      },
    });

  const renderChildren =
    typeof children === "function" ? children({ handleClose }) : children;

  return (
    <React.Fragment>
      {ModalButtonWithProps || (
        <Button
          variant="outlined"
          onClick={handleOpen}
          sx={{
            display: hiddenButton ? "none" : "block",
          }}
        >
          {label ?? "button"}
        </Button>
      )}
      <ModalV5
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box className="Box-modal-content-main" sx={style}>
          <Box sx={{ p: 1.5 }} />
          <Box
            sx={{
              overflow: "auto",
              maxHeight: "80vh",
              bgcolor: "background.paper",
            }}
            padding={{ xs: 1.1, md: 2 }}
          >
            <Grid
              container
              sx={{
                justifyContent: "center",
                textAlign: { xs: "left", lg: "center" },
              }}
            >
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "right" }}
              >
                <IconButton onClick={handleClose}>
                  <CloseOutlinedIcon fontSize="large" />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {renderChildren}
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ p: 1.5 }} />
        </Box>
      </ModalV5>
    </React.Fragment>
  );
};

export default Modal;
