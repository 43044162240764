import { useHomeUserContext } from "@context/HomeUserContext";
import { useIoCContext } from "@context/IoCContext/IoCContext";
import { Types } from "@ioc/types";
import { IProductQuery } from "@modules/product/dtos/IProductQuery";
import {
  IDiscountProduct,
  IDiscountProductResponse,
} from "@modules/product/dtos/IProductResponse";
import { ISaveProductDTO } from "@modules/product/dtos/ISaveProductDTO";
import { IProductService } from "@modules/product/models/IProductService";
import AppError from "@utils/AppError";
import { enqueueSnackbar } from "notistack";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

const ITEMS_PER_PAGE = 10;
const FIRST_PAGE = 1;

interface IDiscountProductContext {
  getProductList: (page?: number) => Promise<void>;
  productResponse: IDiscountProductResponse;
  setProductResponse: React.Dispatch<
    React.SetStateAction<IDiscountProductResponse>
  >;
  isProductListLoading: boolean;
  setIsProductListLoading: React.Dispatch<React.SetStateAction<boolean>>;
  productQuery: IProductQuery;
  setProductQuery: React.Dispatch<React.SetStateAction<IProductQuery>>;
  isSaveProductLoading: boolean;
  setIsSaveProductLoading: React.Dispatch<React.SetStateAction<boolean>>;
  saveProduct: (
    body: ISaveProductDTO,
    handleClose?: () => void
  ) => Promise<void>;
  isDeleteProductLoading: boolean;
  setIsDeleteProductLoading: React.Dispatch<React.SetStateAction<boolean>>;
  deleteProduct: (id: string, handleClose?: () => void) => Promise<void>;
  openDrawer: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  editProduct: IDiscountProduct | undefined;
  setEditProduct: React.Dispatch<
    React.SetStateAction<IDiscountProduct | undefined>
  >;
  updateProduct: (
    body: ISaveProductDTO,
    handleClose?: () => void
  ) => Promise<void>;
  registeredProducts: string[];
}

const DiscountProductContext = createContext<IDiscountProductContext>(
  {} as IDiscountProductContext
);

const DiscountProductProvider: React.FC = ({ children }) => {
  const { documentList } = useHomeUserContext();
  const cnpjSelected = documentList.filter((item) => item.isSelected)[0];
  const iocContext = useIoCContext();
  const getProductService = iocContext.serviceContainer.get<IProductService>(
    Types.Product.IProductService
  );
  const initFilter: IProductQuery = {
    cnpj: cnpjSelected.cnpj,
    page: 1,
    limit: ITEMS_PER_PAGE,
  };
  const [productResponse, setProductResponse] = useState<
    IDiscountProductResponse
  >({});
  const [isProductListLoading, setIsProductListLoading] = useState(false);
  const [isSaveProductLoading, setIsSaveProductLoading] = useState(false);
  const [isDeleteProductLoading, setIsDeleteProductLoading] = useState(false);
  const [productQuery, setProductQuery] = useState<IProductQuery>(initFilter);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [editProduct, setEditProduct] = useState<IDiscountProduct | undefined>(
    undefined
  );

  const getProductList = useCallback(
    async (page?: number) => {
      try {
        setIsProductListLoading(true);
        const response = await getProductService.list({
          ...productQuery,
          cnpj: cnpjSelected.cnpj,
          page: page ?? productQuery.page,
        });

        setProductResponse(response);
      } catch (error) {
        if (error instanceof AppError) {
          enqueueSnackbar(`Erro ao carregar produtos - ${error.message}`, {
            variant: "error",
            style: { fontSize: "1.4rem" },
          });
        }
      } finally {
        setIsProductListLoading(false);
      }
    },
    [cnpjSelected.cnpj, getProductService, productQuery]
  );

  const registeredProducts = useMemo(
    () => productResponse.content?.map((item) => `${item.name}`) ?? [],
    [productResponse]
  );

  const saveProduct = useCallback(
    async (body: ISaveProductDTO, handleClose?: () => void) => {
      try {
        setIsSaveProductLoading(true);
        await getProductService.save(body);
        getProductList(FIRST_PAGE);
        handleClose && handleClose();
      } catch (error) {
        if (error instanceof AppError) {
          enqueueSnackbar(`Erro ao salvar produto - ${error.message}`, {
            variant: "error",
            style: { fontSize: "1.4rem" },
          });
        }
      } finally {
        setIsSaveProductLoading(false);
      }
    },
    [getProductList, getProductService]
  );

  const updateProduct = useCallback(
    async (body: ISaveProductDTO, handleClose?: () => void) => {
      try {
        setIsSaveProductLoading(true);
        await getProductService.edit(body);
        getProductList(FIRST_PAGE);
        handleClose && handleClose();
      } catch (error) {
        if (error instanceof AppError) {
          enqueueSnackbar(`Erro ao atualizar produto - ${error.message}`, {
            variant: "error",
            style: { fontSize: "1.4rem" },
          });
        }
      } finally {
        setIsSaveProductLoading(false);
      }
    },
    [getProductList, getProductService]
  );

  const deleteProduct = useCallback(
    async (id: string, handleClose?: () => void) => {
      try {
        setIsDeleteProductLoading(true);
        await getProductService.delete(id);
        getProductList(FIRST_PAGE);
        enqueueSnackbar(`Produto excluído com sucesso`, {
          variant: "success",
          style: { fontSize: "1.4rem" },
        });
      } catch (error) {
        if (error instanceof AppError) {
          enqueueSnackbar(`Erro ao excluir produto - ${error.message}`, {
            variant: "error",
            style: { fontSize: "1.4rem" },
          });
        }
      } finally {
        setIsDeleteProductLoading(false);
        handleClose && handleClose();
      }
    },
    [getProductList, getProductService]
  );

  return (
    <DiscountProductContext.Provider
      value={{
        getProductList,
        productResponse,
        setProductResponse,
        isProductListLoading,
        setIsProductListLoading,
        productQuery,
        setProductQuery,
        saveProduct,
        isSaveProductLoading,
        setIsSaveProductLoading,
        isDeleteProductLoading,
        setIsDeleteProductLoading,
        deleteProduct,
        openDrawer,
        setOpenDrawer,
        editProduct,
        setEditProduct,
        updateProduct,
        registeredProducts,
      }}
    >
      {children}
    </DiscountProductContext.Provider>
  );
};

const useDiscountProductContext = (): IDiscountProductContext => {
  const context = useContext(DiscountProductContext);
  if (!context) {
    throw new Error(
      "useDiscountProductContext deve ser utilizado dentro de um DiscountProductProvider"
    );
  }
  return context;
};

export { useDiscountProductContext, DiscountProductProvider };
