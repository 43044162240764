import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { ICreateUserDTO } from "../dtos/ICreateUserDTO";
import { ICreateUserService } from "../models/ICreateUserService";

@injectable()
export class CreateUserService implements ICreateUserService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  public async execute(data: ICreateUserDTO): Promise<{ message: string }> {
    // transform object to JSON
    const parseCNPJ = data.attributes.CNPJ.map((cnpjData) => cnpjData);

    const newData = {
      ...data,
      attributes: {
        INTERNAL_NAME: data.attributes.INTERNAL_NAME, 
        BPID: data.attributes.BPID, 
        CNPJ: parseCNPJ, 
      },
    };


    return this.httpInstance.post<{ message: string }>("/users", newData);
  }
}
