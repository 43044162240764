import {
  Box,
  Checkbox,
  createStyles,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  Typography,
  useTheme,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    itemSelect: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    itemSelectTitle: {
      color: theme.palette.primary.highlightedplus,
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      marginTop: 10,
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    itemSelectSubTitle: {
      color: theme.palette.shadesOfDark.ultraLight,
      fontFamily: "Montserrat",
      fontSize: "12px",
      fontWeight: 500,
      padding: 0,
    },
    customSelect: {
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      marginTop: "0",
      height: "100%",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      fontFamily: "Montserrat",
      fontSize: "16px",
      fontWeight: 400,
      paddingLeft: "14px",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.shadesOfDark.light,
      },
      "& .MuiSvgIcon-root": {
        fill: "#D91F05",
      },
      "& .Mui-disabled": {
        fill: theme.palette.shadesOfDark.medium,
        cursor: "not-allowed",
        color: theme.palette.shadesOfDark.medium,
      },
      borderRadius: "8px",
    },
    formControl: {
      borderRadius: "8px",
      width: "100%",
      height: "56px",
    },
    inputLabel: {
      position: "absolute",
      color: "#3E3D3D",
      fontFamily: "Montserrat",
      fontSize: "1.6rem",
      backgroundColor: "#fff",
      paddingLeft: "0.2rem",
      paddingRight: "0.8rem",
      marginLeft: "0rem",
      width: "auto",
    },
  })
);

interface DropdownOutlinedProps<T> {
  label: string;
  error?: boolean;
  value?: T | T[];
  options: T[];
  messageError?: string;
  multiple?: boolean;
  disabled?: boolean;
  getItemLabel: (value: T) => string;
  getItemSubtitle?: (value: T) => string;
  disableItem?: (value: T) => boolean;
  onChange?: (value: T) => void;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  renderValue?: (value: SelectProps["value"]) => React.ReactNode;
}

function DropdownOutlined<T>({
  label,
  error,
  onBlur,
  value,
  options,
  messageError,
  getItemLabel,
  getItemSubtitle,
  disableItem,
  onChange,
  renderValue,
  multiple = false,
  disabled = false,
}: DropdownOutlinedProps<T>) {
  const classes = useStyles();
  const theme = useTheme();

  const valueAsString = (value?: T | T[]) => value && JSON.stringify(value);

  const isObject = (value: T) => typeof value === "object";

  const isString = (value: T) => typeof value === "string";

  const checked = (option: T) => (value as T[]).some((item) => item === option);

  const getValue = () => {
    if (Array.isArray(value) || isString(value as T)) {
      return value;
    }
    return valueAsString(value);
  };

  const handleChange = ({ target }) => {
    let newValue: T = "" as T;
    try {
      newValue = JSON.parse(target.value as string);
    } catch (error) {
      newValue = target.value as T;
    }
    onChange && onChange(newValue);
  };

  return (
    <Box>
      <FormControl fullWidth className={classes.formControl} variant="outlined">
        <InputLabel
          className={classes.inputLabel}
          style={{
            color: disabled
              ? theme.palette.shadesOfDark.medium
              : theme.palette.text.primary,
          }}
        >
          {label}
        </InputLabel>
        <Select
          className={classes.customSelect}
          error={error}
          onBlur={onBlur}
          value={getValue()}
          IconComponent={ExpandMoreIcon}
          onChange={handleChange}
          multiple={multiple}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
          renderValue={renderValue}
          disabled={disabled}
        >
          {options.map((option, index) => (
            <MenuItem
              key={String(`${getItemLabel(option)}${index}`)}
              value={
                isObject(option) ? valueAsString(option) : (option as string)
              }
              className={classes.itemSelect}
              disabled={disableItem && disableItem(option)}
            >
              <Grid container>
                {multiple && (
                  <Grid item xs={2}>
                    <Checkbox
                      style={{ color: theme.palette.primary.main }}
                      checked={checked(option)}
                    />
                  </Grid>
                )}
                <Grid item xs={10}>
                  <Typography className={classes.itemSelectTitle}>
                    {getItemLabel(option)}
                  </Typography>
                  {getItemSubtitle && (
                    <Typography className={classes.itemSelectSubTitle}>
                      {getItemSubtitle(option)}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText error={error} style={{ marginLeft: "14px" }}>
        {messageError}
      </FormHelperText>
    </Box>
  );
}

export default DropdownOutlined;
