import { Box, Grid, Portal, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import {
  IRoadLoadAllNormalized,
  IRoadRowCount,
} from "@pages/User/RoadLoad/model";
import { useRoadLoad } from "@pages/User/RoadLoad/context/RoadLoadContext";
import HomeRoadLoad from "@pages/User/RoadLoad/view/HomeRoadLoad";
import AllRoadLoad from "@pages/User/RoadLoad/view/AllRoadLoad";
import Notification from "@components/Notification";
import ROUTES from "@config/routes";
import Layout from "@components/Layout";
import BreadcrumbAndTitle from "@components/BreadcrumbAndTitle";
import { BreadcrumbChild } from "@components/BreadcrumbCustom";

const ViewLoadRoad: React.FC = () => {
  const { roadLoad, normalizedRoadLoad, handleRoadLoadMethod } = useRoadLoad();

  const roadNormalized: IRoadLoadAllNormalized = [
    "upcomingScheduled",
    "scheduledNormalized",
    "waitingNormalized",
    "finishedNormalized",
    "blockedNormalized",
    "canceledNormalized",
  ].reduce((acc, key) => {
    acc[key] = normalizedRoadLoad(roadLoad)?.[key];
    return acc;
  }, {} as IRoadLoadAllNormalized);

  const roadRowsCount: IRoadRowCount = [
    "waiting",
    "scheduled",
    "finished",
    "blocked",
    "canceled",
  ].reduce((acc, key) => {
    acc[`${key}Row`] = roadLoad?.[key]?.total ?? 0;
    return acc;
  }, {} as IRoadRowCount);

  const sessionSheetPageRoadLoad = sessionStorage.getItem("sheetRoadLoad");
  const parsedSheetPageRoadLoad =
    sessionSheetPageRoadLoad !== "undefined"
      ? JSON.parse(sessionSheetPageRoadLoad || "false")
      : false;

  const [sheetControl, setSheetControl] = useState(parsedSheetPageRoadLoad);

  const handleShowAllLoads = (tab: number): void => {
    handleRoadLoadMethod.handleSetTabSelected(tab);
    setSheetControl(true);
    sessionStorage.setItem("sheetRoadLoad", JSON.stringify(true));
  };

  const handleHiddenAllLoads = (): void => {
    setSheetControl(false);
    sessionStorage.setItem("sheetRoadLoad", JSON.stringify(false));
  };

  const breadcrumbDataRoadLoad: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.HOME,
      label: "Início",
    },
    {
      label: "Agenda e Vem",
    },
  ];

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Layout disableToolbarMobile={true} disableToolbarCNPJ={true}>
      <Box
        style={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <BreadcrumbAndTitle
          breadcrumbData={breadcrumbDataRoadLoad}
          title="Agenda e Vem"
        />
      </Box>

      {isMobile && (
        <Portal>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: { xs: "right", md: "left" },
              height: "30px",
              position: { xs: "absolute", md: "" },
              top: 10,
              right: 20,
              zIndex: 1201,
            }}
          >
            <Notification />
          </Grid>
        </Portal>
      )}

      <Box
        style={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Grid item xs={12} className="AllRoadLoad-HomeRoadLoad-item">
          {sheetControl ? (
            <AllRoadLoad
              handleHiddenAllRoadLoad={handleHiddenAllLoads}
              road={roadNormalized}
              roadCount={roadRowsCount}
            />
          ) : (
            <HomeRoadLoad
              handleShowAllRoadLoad={handleShowAllLoads}
              road={roadNormalized}
              roadCount={roadRowsCount}
            />
          )}
        </Grid>
      </Box>
    </Layout>
  );
};

export default ViewLoadRoad;
