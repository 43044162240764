import BreadcrumbAndTitle from "@components/BreadcrumbAndTitle";
import { BreadcrumbChild } from "@components/BreadcrumbCustom";
import ButtonLinkGeneric from "@components/ButtonLinkGeneric/ButtonLinkGeneric";
import ROUTES from "@config/routes";
import { Grid } from "@mui/material";
import React from "react";

const OpenNewReclamation: React.FC = () => {
  const breadcrumbDataOrders: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.HOME,
      label: "Início",
    },
    {
      label: "Fale conosco",
    },
  ];

  return (
    <Grid
      container
      className="criar-nova-reclamacao"
      alignItems={"center"}
      justifyContent={"space-between"}
      sx={{ border: "0px solid red" }}
    >
      <Grid item xs={6}>
        <BreadcrumbAndTitle
          breadcrumbData={breadcrumbDataOrders}
          title="Fale conosco"
        />
      </Grid>

      <Grid item>
        <ButtonLinkGeneric
          text={"Abrir novo registro"}
          link={ROUTES.USER_ROUTES.RECLAMATION}
        />
      </Grid>
    </Grid>
  );
};

export default OpenNewReclamation;
