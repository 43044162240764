import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ListItem, ListItemIcon, Typography } from "@material-ui/core";
import clsx from "clsx";

interface SidebarMenuItemProps {
  item: {
    icon: React.ReactNode;
    iconActive: React.ReactNode;
    text: string;
    link: string;
    onClick?: () => void;
  };
  classes: Record<string, string>;
}

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = ({ item, classes }) => {
  const location = useLocation();

  return (
    <ListItem
      button
      className={clsx(classes.listItem)}
      classes={{
        selected: classes.listItemSelected,
        button: classes.listItemHover,
      }}
      selected={location?.pathname === item?.link}
      component={Link}
      to={item?.link}
      onClick={item?.onClick}
    >
      <ListItemIcon>
        <div
          className={
            location?.pathname === item?.link
              ? classes.borderMenu
              : classes.borderMenuNormal
          }
        ></div>
        {location?.pathname === item?.link ? item?.iconActive : item?.icon}
      </ListItemIcon>
      <Typography
        className={
          location?.pathname === item?.link
            ? classes.textMenuItemSelected
            : classes.textMenuItem
        }
      >
        {item.text}
      </Typography>
    </ListItem>
  );
};

export default SidebarMenuItem;
