import React from "react";

import {
  BlockFinancialIcon,
  RoadLoadRedIcon,
  ClockIcon,
  CheckIcon,
} from "@components/Icons";

import { ISalesOrder, IScheduledLoad } from "../../model";
import { KEY } from "@utils/enum";

export const tableHeaderScheduledLoadOrders: {
  key: keyof ISalesOrder;
  label: string;
  align?;
  width?;
}[] = [
  {
    key: KEY.ORDER,
    label: "Ordem de Venda",
  },
  {
    key: KEY.CNPJ,
    label: "CNPJ/CPF",
  },
  {
    key: KEY.CUSTOMER,
    label: "Cliente",
  },
  {
    key: KEY.PRODUCT,
    label: "Produto",
  },
  {
    key: KEY.QUANTITY,
    label: "Quantidade",
    align: "right",
  },
  {
    key: KEY.DELIVERYDATE,
    label: "Data de Entrega",
    align: "center",
  },
  {
    key: KEY.STATUS,
    label: "Status",
    align: "center",
  },
];

export const tableHeaderUnscheduled = [
  {
    key: KEY.ICON,
    label: () => <ClockIcon style={{ width: 40, height: 40 }} />,
  },
  {
    key: KEY.CUSTOMER,
    label: "Cliente",
  },
  {
    key: KEY.CNPJ,
    label: "CNPJ/CPF",
  },
  {
    key: KEY.FILIALNAME,
    label: "Filial",
  },
  {
    key: KEY.PRODUCT,
    label: "Produto",
  },
  {
    key: KEY.QUANTITY,
    label: "Quantidade",
    align: "right",
  },
  {
    key: KEY.ORDER,
    label: "Ordem de venda",
    align: "right",
  },
  {
    key: KEY.FREIGHT,
    label: "Frete",
    align: "right",
  },
  {
    key: KEY.RELEASEDATE,
    label: "Data de entrega",
    align: "center",
  },
];

export const tableHeaderScheduled: {
  key: keyof IScheduledLoad;
  label;
  align?;
  width?;
}[] = [
  {
    key: KEY.ICON,
    label: () => <RoadLoadRedIcon style={{ width: 40, height: 40 }} />,
  },
  {
    key: KEY.REF,
    label: "N° Agendamento",
    width: "fit-content",
    align: "center",
  },
  {
    key: KEY.TOTALAMOUNTLITERS,
    label: "Quant. Total em L.",
    align: "right",
    width: "fit-content",
  },
  {
    key: KEY.TRUCKDRIVER,
    label: "Motorista",
  },
  {
    key: KEY.PLATE,
    label: "Placa",
  },
  {
    key: KEY.FREIGHT,
    label: "Frete",
    align: "right",
  },
  {
    key: KEY.BPID,
    label: "BP-Transportadora",
    align: "right",
  },
  {
    key: KEY.FILIALNAME,
    label: "Filial",
  },
  {
    key: KEY.SALESORDERQUANTITY,
    label: "Quant. Ordens de Venda",
    align: "center",
  },
  {
    key: KEY.SCHEDULEDATE,
    label: "Agendado para",
    align: "center",
  },
];

export const tableHeaderFinished: {
  key: keyof IScheduledLoad;
  label;
  align?;
  width?;
}[] = [
  {
    key: KEY.ICON,
    label: () => <CheckIcon style={{ width: 40, height: 40 }} />,
  },
  {
    key: KEY.REF,
    label: "N° Agendamento",
    width: "fit-content",
    align: "center",
  },
  {
    key: KEY.TOTALAMOUNTLITERS,
    label: "Quant. Total em L.",
    align: "right",
    width: "fit-content",
  },
  {
    key: KEY.TRUCKDRIVER,
    label: "Motorista",
  },
  {
    key: KEY.PLATE,
    label: "Placa",
  },
  {
    key: KEY.FILIALNAME,
    label: "Filial",
  },
  {
    key: KEY.SALESORDERQUANTITY,
    label: "Quant. Ordens de Venda",
    align: "center",
  },
  {
    key: KEY.FREIGHT,
    label: "Frete",
    align: "right",
  },
  {
    key: KEY.SCHEDULEDATE,
    label: "Concluido",
    align: "left",
  },
];

export const tableHeaderCanceled: {
  key: keyof IScheduledLoad;
  label: string | Function;
  align?;
  width?;
}[] = [
  {
    key: KEY.ICON,
    label: () => <RoadLoadRedIcon style={{ width: 40, height: 40 }} />,
  },
  {
    key: KEY.REF,
    label: "N° Agendamento",
    width: "fit-content",
    align: "center",
  },
  {
    key: KEY.TRUCKDRIVER,
    label: "Motorista",
  },

  {
    key: KEY.FILIALNAME,
    label: "Filial",
    align: "left",
  },
  {
    key: KEY.REASONCANCELLATION,
    label: "Motivo do Cancelamento",
    align: "center",
  },
  {
    key: KEY.TOTALAMOUNTLITERS,
    label: "Quant. Total em L.",
    align: "right",
    width: "fit-content",
  },
  {
    key: KEY.SALESORDERQUANTITY,
    label: "Quant. Ordens de Venda",
    align: "center",
  },
  {
    key: KEY.FREIGHT,
    label: "Frete",
    align: "right",
  },
  {
    key: KEY.SCHEDULEDATE,
    label: "Foi Agendado em",
    align: "center",
  },
];

export const tableHeaderBlocked = [
  {
    key: KEY.ICON,
    label: () => <BlockFinancialIcon style={{ width: 40, height: 40 }} />,
  },
  {
    key: KEY.CUSTOMER,
    label: "Cliente",
  },
  {
    key: KEY.CNPJ,
    label: "CNPJ/CPF",
  },
  {
    key: KEY.FILIALNAME,
    label: "Filial",
  },
  {
    key: KEY.PRODUCT,
    label: "Produto",
  },
  {
    key: KEY.QUANTITY,
    label: "Quantidade",
    align: "right",
  },
  {
    key: KEY.ORDER,
    label: "Ordem de venda",
    align: "right",
  },
  {
    key: KEY.FREIGHT,
    label: "Frete",
    align: "right",
  },
  {
    key: KEY.BLOCKEDDATE,
    label: "Data de entrega",
    align: "center",
  },
];
