import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useIoCContext } from "@context/IoCContext/IoCContext";
import { useUserState } from "@context/UserContext";
import useDialogAlert from "@hooks/useDialogAlert";
import { Types } from "@ioc/types";
import { IGetClientInfoService } from "@modules/orders/models/IGetClientInfoService";
import AppError from "@utils/AppError";
import { IClient } from "@utils/interfaces";
import { getCNPJinLocalStorage } from "@utils/localStorageUtils";

export interface IDocument {
  cnpj: string;
  label: string;
  isSelected?: boolean;
}

interface IHomeContext {
  document?: string;
  documents?: string;
  documentIndex: number;
  tabPanelIndex: number;
  CNPJDetailsLoad: boolean;
  CNPJDetails: IClient | null;
  documentSelected: IDocument;
  handleFecthCNPJDetails: (cnpjSelectec: string) => void;
  setDocument: React.Dispatch<React.SetStateAction<string>>;
  setDocumentIndex: React.Dispatch<React.SetStateAction<number>>;
  setDocuments: React.Dispatch<React.SetStateAction<string>>;
  setTabPanelIndex: React.Dispatch<React.SetStateAction<number>>;
  documentList: IDocument[];
  setDocumentList: React.Dispatch<React.SetStateAction<IDocument[]>>;
  isShowTableLastOrder: Boolean;
  setIsShowTableLastOrder: React.Dispatch<React.SetStateAction<Boolean>>;
}

const HomeUserContext = createContext<IHomeContext>({} as IHomeContext);

const HomeUserContextProvider: React.FC = ({ children }) => {
  const { snackbar } = useDialogAlert();
  const iocContext = useIoCContext();
  const getClientInfoService = iocContext.serviceContainer.get<
    IGetClientInfoService
  >(Types.Orders.IGetClientInfoService);

  const userState = useUserState();
  const [isShowTableLastOrder, setIsShowTableLastOrder] = useState<Boolean>(
    true
  );

  const SELECTED_CNPJ_STORAGE = getCNPJinLocalStorage("cnpjSelected");
  const [document, setDocument] = useState<string>(() => {
    if (SELECTED_CNPJ_STORAGE) {
      return SELECTED_CNPJ_STORAGE;
    }
    return userState["listCNPJ"][0]?.CNPJ;
  });

  const [documentIndex, setDocumentIndex] = useState<number>(0);
  const [documents, setDocuments] = useState<string>(() => {
    return userState["listCNPJ"][documentIndex]?.CNPJ;
  });

  const [tabPanelIndex, setTabPanelIndex] = useState<number>(-1);
  const [documentList, setDocumentList] = useState<IDocument[]>(() => {
    return userState["listCNPJ"].map((item, index) => ({
      cnpj: item.CNPJ,
      label: item.companyName,
      isSelected: document === item.CNPJ,
    }));
  });

  const [CNPJDetailsLoad, setCNPJDetailsLoad] = useState<boolean>(false);
  const [CNPJDetails, setCNPJDetails] = useState<IClient | null>(null);

  const documentSelected = useMemo(
    () =>
      documentList.filter((item) =>
        SELECTED_CNPJ_STORAGE
          ? item.cnpj === SELECTED_CNPJ_STORAGE
          : item.isSelected
      )[0],
    [SELECTED_CNPJ_STORAGE, documentList]
  );

  const handleFecthCNPJDetails = useCallback(
    async (CNPJSelected: string | undefined) => {
      try {
        setCNPJDetailsLoad(true);
        if (!CNPJSelected) throw new Error("Erro ao buscar detalhes do CNPJ");

        const CNPJDetailsResponse = await getClientInfoService.execute(
          CNPJSelected
        );
        setCNPJDetails(CNPJDetailsResponse);
      } catch (error) {
        if (error instanceof AppError) {
          console.error(error.message);
          snackbar({
            message:
              "Ocorreu um erro ao recuperar dados do CNPJ selecionado, recarregando a página ...",
            variant: "error",
          });
        }
      } finally {
        setCNPJDetailsLoad(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    localStorage.setItem("cnpjSelected", document);
    handleFecthCNPJDetails(document);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document]);

  return (
    <HomeUserContext.Provider
      value={{
        document,
        documentSelected,
        setDocument,
        documents,
        setDocuments,
        documentIndex,
        setDocumentIndex,
        tabPanelIndex,
        setTabPanelIndex,
        documentList,
        setDocumentList,
        CNPJDetailsLoad,
        CNPJDetails,
        handleFecthCNPJDetails,
        isShowTableLastOrder,
        setIsShowTableLastOrder,
      }}
    >
      {children}
    </HomeUserContext.Provider>
  );
};

const useHomeUserContext = (): IHomeContext => {
  const context = useContext(HomeUserContext);
  if (!Object.values(context).length) {
    throw new Error(
      "useHomeContext deve ser utilizado dentro de um HomeProvider"
    );
  }
  return context;
};

export { HomeUserContextProvider, useHomeUserContext };
