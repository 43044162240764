import { Types } from "@ioc/types";
import { IHttpService } from "@modules/infra/http/models/IHttpService";
import { inject, injectable } from "inversify";
import { IGetBpIdDTO } from "../dtos/IGetBpIdDTO";
import { IGetBpIdService } from "../models/IGetBpIdService";

@injectable()
export class GetBpIdService
  implements IGetBpIdService {
  // @ts-ignore
  @inject(Types.IHttpService) private httpInstance: IHttpService;

  execute(bpId: string): Promise<IGetBpIdDTO> {
    return this.httpInstance.get<IGetBpIdDTO>(
      `/filters/carrier/${bpId}`
    );
  }
}
