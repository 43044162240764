import Layout from "@components/Layout";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { AppointmentPriceProvider } from "./AppointmentPriceContext";
import { FormQuery } from "./FormQuery";
import { FormQueryPricesValidationSchema } from "./FormQueryPricesValidationSchema";
import { IQuery } from "./interface";
import { TablePrices } from "./TablePrices";
import BreadcrumbAndTitle from "@components/BreadcrumbAndTitle";
import { BreadcrumbChild } from "@components/BreadcrumbCustom";
import ROUTES from "@config/routes";
import { Box } from "@mui/material";

const PageContainer: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

  const breadcrumbDataOrders: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.HOME,
      label: "Início",
    },
    {
      label: "Preços de produtos Atem",
    },
  ];

  const initialValues: IQuery = {
    CNPJ: "",
    address: null,
    filialID: "",
    withdrawBasisID: "",
    withdrawBasisBranchName: "",
    freightType: "CIF",
  };

  return (
    <Layout>
      <Box
        style={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <BreadcrumbAndTitle
          breadcrumbData={breadcrumbDataOrders}
          title="Preços de produtos Atem"
        />
      </Box>
      <Box
        style={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={FormQueryPricesValidationSchema}
          onSubmit={async (values) => {}}
        >
          <Form>
            <FormQuery />
            <TablePrices />
          </Form>
        </Formik>
      </Box>
    </Layout>
  );
};

const AppointmentPrice: React.FC = () => {
  return (
    <AppointmentPriceProvider>
      <PageContainer />
    </AppointmentPriceProvider>
  );
};

AppointmentPrice.displayName = "AppointmentPrice";

export { AppointmentPrice };
