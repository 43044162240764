export enum KEY {
  REF = "Ref",
  SCHEDULEGROUPID = "scheduleGroupID",
  SALESORDERQUANTITY = "salesOrderQuantity",
  TOTALAMOUNTLITERS = "totalAmountLiters",
  SCHEDULEID = "scheduleID",
  CUSTOMER = "customer",
  PRODUCT = "product",
  QUANTITY = "quantity",
  CNPJ = "cnpj",
  DELIVERYDATE = "deliveryDate", // Data de Entrega
  RELEASEDATE = "releaseDate", // Data de Liberação
  INVOICEDATE = "invoiceDate", // Data de Faturamento
  BLOCKEDDATE = "blockedDate", // Data de Bloqueio
  TRUCKDRIVER = "truckDriver",
  PLATE = "plate",
  FILIAL = "filial",
  FILIALNAME = "filialName",
  ORDER = "order",
  SCHEDULEDATE = "scheduleDate",
  STATUS = "status",
  ACTION = "action",
  ACTIONCHECK = "actionCheck",
  FREIGHT = "freight",
  BPID = "bpId",
  ICON = "icon",
  DRIVER = "truckDriver",
  REASONCANCELLATION = "reasonCancellation",
  DESCRIPTIONCANCELLATION = "descriptionCancellation",
}

export enum STATUSTYPE {
  FT = "FT",
  BL = "BL",
  LIB = "LIB",
  PENDING = "PENDING",
  FINISHED = "FINISHED",

  FATURADO = "Faturado",
  BLOQUEADO = "Bloqueado",
  LIBERADO = "Liberado",
  CANCELADO = "Cancelado",
}

export enum STATUSROADLOAD {
  SCHEDULED = "SCHEDULED",
  WAITING = "WAITING_SCHEDULE",
  FINISHED = "FINISHED",
  BLOCKED = "BLOCKED",
  CANCELED = "CANCELED",
  UPCOMING_SCHEDULE = "UPCOMING_SCHEDULE",
}

export enum FileType {
  PDF = "pdf",
  XML = "xml",
}

export enum User {
  USER_NAME = "nameLogin",
  SIDEBAR_ADMIN = "sidebarAdmin",
}
