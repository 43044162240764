import {
  Box,
  Collapse,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useState } from "react";
import PageNotFound from "@components/PageNotFound/PageNotFound";
import { useDiscountContext } from "./DiscountContext";
import { CactusIcon } from "@components/Icons";
import ModalDiscount from "./ModalDiscount";
import { Discount } from "@modules/discount/model/IDiscountService";
import TableRowScoped from "./TableRowScope";
import SkeletonTableOrder from "../MyOrders/SkeletonTableOrder";

interface TableDiscountProps {
  isLoading?: boolean;
  companyName: string;
}
const TableDiscount: React.FC<TableDiscountProps> = ({
  isLoading = false,
  companyName,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const { mappedDiscounts } = useDiscountContext();
  const [openModal, setOpenModal] = useState({
    open: false,
    discount: null,
  });

  const cleanModalState = () => {
    setOpenModal((prev) => {
      return {
        ...prev,
        open: false,
        discount: null,
      };
    });
  };

  if (isLoading) {
    return <SkeletonTableOrder rows={6} cols={isMobile ? 2 : 10} />;
  }

  return (
    <>
      {mappedDiscounts.length ? (
        <Collapse in>
          <Box style={{ overflowX: isMobile ? "auto" : "hidden" }}>
            <Table>
              <Grid
                xs={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Typography className={classes.titleSearch}>
                  Resultados da pesquisa para: <span>{companyName}</span>
                </Typography>{" "}
              </Grid>
              <Paper className={classes.paperGrid}>
                <Grid
                  xs={12}
                  style={{ display: "flex", fontWeight: "bold" }}
                  className={classes.gridColumn}
                >
                  <Grid item xs={3} className={classes.gridItemColumn}>
                    Descrição
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 4 : 1}
                    className={classes.gridItemColumn}
                  >
                    Código PDV
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    className={classes.gridItemColumn}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    Unidade
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 3 : 2}
                    className={classes.gridItemColumn}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    Preço
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 3 : 2}
                    className={classes.gridItemColumn}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    Descontos
                  </Grid>

                  <Grid
                    item
                    xs={isMobile ? 3 : 2}
                    className={classes.gridItemColumn}
                    style={{
                      justifyContent: "center",
                    }}
                  >
                    Método de pagamento
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 3 : 1}
                    className={classes.gridItemColumn}
                    style={{
                      justifyContent: "center",
                    }}
                  ></Grid>
                </Grid>
              </Paper>

              <TableBody style={{ overflowX: "auto" }}>
                {mappedDiscounts.map((discount: Discount, idx: number) => (
                  <TableRowScoped
                    onOpen={(discountToEdit: any) =>
                      setOpenModal((prev) => {
                        return {
                          ...prev,
                          open: true,
                          discount: discountToEdit,
                        };
                      })
                    }
                    discount={discount}
                    key={idx + (discount?.description || "")}
                  />
                ))}
              </TableBody>
            </Table>
            <ModalDiscount data={openModal} onClose={() => cleanModalState()} />
          </Box>
        </Collapse>
      ) : (
        <Box style={{ overflowX: isMobile ? "auto" : "hidden" }}>
          <PageNotFound
            title="Não há nada para mostrar"
            subtitle={`Não houve resultado para o posto ${companyName}. Por favor tente outro posto.`}
            icon={<CactusIcon />}
          />
        </Box>
      )}
    </>
  );
};

export default TableDiscount;

const useStyles = makeStyles((theme) =>
  createStyles({
    tableRow: {
      marginTop: "2rem",
    },
    grid: { height: "90px", marginTop: 16, marginBottom: 16 },
    gridColumn: { height: "60px", marginTop: 16, marginBottom: 16 },
    gridCustomColumn: { marginTop: 16, marginBottom: 16 },
    gridItem: {
      display: "flex",
      color: theme.palette.primary.highlightedplus,
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "20px",
      padding: "23px 0",
    },
    gridItemColumn: {
      color: theme.palette.primary.highlightedplus,
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 700,
      display: "flex",
      alignItems: "center",
    },
    paperGrid: {
      borderRadius: "8px",
      padding: "0px 57px 0px 32px",
      boxShadow: `0px 4px 4px rgba(115, 97, 97, 0.10)`,
    },
    titleSearch: {
      color: theme.palette.primary.highlightedplus,
      textAlign: "center",
      fontFamily: "DM Sans",
      fontSize: "16px",
      fontWeight: 400,
      "& > span": {
        color: theme.palette.shadesOfDark.black,
        fontWeight: 700,
      },
    },
  })
);
