import { IQueryOrderDataDTO } from "@modules/orders/dtos/IQueryOrderDataDTO";

export interface IQuery {
  CNPJ: string[];
  filterBy: TypeFilterDate;
  startDate: Date | null;
  dateRange: [Date | null, Date | null] | null | undefined;
  endDate: Date | null;
  statusSale: string[];
}

export const dataQueryMock: IQueryOrderDataDTO[] = [
  {
    CNPJ: "31860017000142",
    companyName: "POSTO PONTA NEGRA COM VAR DE COMB L",
    numberOrder: "653565",
    deliveryDate: new Date("2023-10-26T20:05:52.000Z"),
    implantationDate: new Date("2023-10-26T04:00:00.000Z"),
    payCond: 1,
    payment: "B",
    paymentDescription: "0001",
    freightType: "CIF",
    productID: "10122",
    productName: "GASOLINA C ADITIVADA",
    quantity: 5000,
    price: 5.5516,
    totalPrice: 27758,
    vehiclePlate: "QZL0G32",
    filialID: "C001",
    total: 27758,
    nfKey: "13231003987364000103550010010617261383408520",
    financialStatus: "D",
    logisticStatus: "C",
    statusPortalCliente: "BL",
    statusAtem: "FT",
  },
];

export enum TypeFilterDate {
  DELIVERY_DATE = "deliveryDate",
  IMPLANTATION_DATE = "implantationDate",
}
