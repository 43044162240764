import React from "react";
import BreadcrumbCustom, {
  BreadcrumbChild,
} from "@components/BreadcrumbCustom";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@material-ui/core";

export interface BreadcrumbAndTitleProps {
  breadcrumbData: BreadcrumbChild[];
  title: string;
}

const BreadcrumbAndTitle: React.FC<BreadcrumbAndTitleProps> = ({
  breadcrumbData,
  title,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  return (
    <Box sx={{ mb: "1rem" }}>
      <Grid xs={12}>
        <BreadcrumbCustom data={breadcrumbData} />
      </Grid>
      <Grid xs={12}>
        <Typography
          style={{
            color: theme.palette.text.primary,
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "700",
            fontSize:
              isMobile || isTablet ? "2rem" : isTablet ? "2.5rem" : "2.4rem",
            marginLeft: isMobile || isTablet ? "10px" : "0px",
            marginTop: "5px",
            paddingLeft: "15px",
          }}
        >
          {title}
        </Typography>
      </Grid>
    </Box>
  );
};

export default BreadcrumbAndTitle;
