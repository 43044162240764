import React, { useEffect, useState } from "react";
import { List } from "@material-ui/core";
import { useFlags } from "flagsmith/react";

import { SIDEBAR_ADMIN_FLAGS } from "@utils/featureFlags";
import ROUTES from "@config/routes";

import {
  CheckListIcon,
  CheckListIconPrimary,
  ColetaIcon,
  ColetaIconActive,
  ContactUsIcon,
  ContactUsIconActive,
  EditIcon,
  EditIconActive,
  EmployeesIcon,
  EmployeesIconActive,
  HomeIcon,
  HomeIconActive,
  ListIcon,
  ListIconActive,
  NozzleActiveIcon,
  NozzleIcon,
  UnionIcon,
  UnionIconActive,
} from "@components/Icons";

import { ISidebarItems } from "../model/ISidebarItems";
import useStylesSidebar from "../model/useStylesSidebar";
import SidebarMenuItem from "./SidebarMenuItem";

const ListDrawerAdmin: React.FC = () => {
  const classes = useStylesSidebar();

  const menuListAdmin: ISidebarItems[] = [
    {
      icon: <HomeIcon className={classes.icon} />,
      iconActive: <HomeIconActive className={classes.icon} />,
      text: "Home Admin",
      link: ROUTES.ADMIN_ROUTES.HOME,
      flag: "admin_sidebar_home",
    },
    {
      icon: <EmployeesIcon className={classes.icon} />,
      iconActive: <EmployeesIconActive className={classes.icon} />,
      text: "Criar Usuário",
      link: ROUTES.ADMIN_ROUTES.NEW_USER,
      flag: "admin_sidebar_create_new_user",
    },
    {
      icon: <CheckListIcon className={classes.icon} />,
      iconActive: <CheckListIconPrimary className={classes.icon} />,
      text: "Listar Usuários",
      link: ROUTES.ADMIN_ROUTES.LIST_USERS,
      flag: "admin_sidebar_list_user",
    },
    {
      icon: <ListIcon className={classes.icon} />,
      iconActive: <ListIconActive className={classes.icon} />,
      text: "Consultar Comprovantes",
      link: ROUTES.ADMIN_ROUTES.LIST_INVOICES_IMAGES,
      flag: "admin_sidebar_consult_check_receipts",
    },
    {
      icon: <EditIcon className={classes.icon} />,
      iconActive: <EditIconActive className={classes.icon} />,
      text: "Enviar Comunicado",
      link: ROUTES.ADMIN_ROUTES.ALL_COMMUNICATION,
      flag: "admin_sidebar_hide_send_communique",
    },
    {
      icon: <UnionIcon className={classes.icon} />,
      iconActive: <UnionIconActive className={classes.icon} />,
      text: "Bloqueio de produtos",
      link: ROUTES.ADMIN_ROUTES.PRODUCT_BLOCK,
      flag: "admin_sidebar_block_products",
    },
    {
      icon: <ContactUsIcon className={classes.icon} />,
      iconActive: <ContactUsIconActive className={classes.icon} />,
      text: "Fale conosco",
      link: ROUTES.ADMIN_ROUTES.RECLAMATION_ANALYSIS,
      flag: "admin_sidebar_contact_us",
    },

    {
      icon: <NozzleIcon className={classes.icon} />,
      iconActive: <NozzleActiveIcon className={classes.icon} />,
      text: "Validar anexo de bico e produto",
      link: ROUTES.ADMIN_ROUTES.NOZZLE,
      flag: "admin_sidebar_nozzle",
    },
    {
      icon: <ListIcon className={classes.icon} />,
      iconActive: <ListIconActive className={classes.icon} />,
      text: "Motorista vs Cliente",
      link: ROUTES.ADMIN_ROUTES.DRIVER_AND_CUSTOMER,
      flag: "admin_sidebar_driver_and_customer",
    },
    {
      icon: <ColetaIcon className={classes.icon} />,
      iconActive: <ColetaIconActive className={classes.icon} />,
      text: "Ir para modo Usuário",
      link: ROUTES.USER_ROUTES.HOME,
      onClick: () => {
        localStorage.setItem("sidebarAdmin", "false");
      },
      flag: "admin_sidebar_redirect_modo_user",
    },
  ];

  const [menuListAdminSideBar, setListMenuAdmin] = useState(menuListAdmin);
  const flags = useFlags(SIDEBAR_ADMIN_FLAGS);

  useEffect(() => {
    const filteredMenusAdmin = menuListAdminSideBar?.filter(
      (menuEntry) => flags[menuEntry.flag].enabled
    );

    setListMenuAdmin(filteredMenusAdmin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <List>
      <List>
        {menuListAdminSideBar.map((item, index) => (
          <SidebarMenuItem key={index} item={item} classes={classes} />
        ))}
      </List>
    </List>
  );
};

export default ListDrawerAdmin;
