import { injectable } from "inversify";
import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";

import { IHttpService } from "@modules/infra/http/models/IHttpService";
import appConfig, { isDevelopment } from "@config/appConfig";
import { goToLogout } from "@utils/index";
import AppError from "@utils/AppError";

@injectable()
export default class AxiosHttpService implements IHttpService {
  // @ts-ignore
  private httpInstance: AxiosInstance;

  static factory(baseURL?: string) {
    if (baseURL) {
      return new AxiosHttpService().setBaseURL(baseURL);
    }
    return new AxiosHttpService().setBaseURL(
      new AxiosHttpService().getBaseUrl()
    );
  }

  getBaseUrl() {
    if (process.env.REACT_APP_ENVIRONMENT === "homologation") {
      return appConfig.api.url.homologation;
    } else if (isDevelopment) {
      return appConfig.api.url.development;
    }
    return appConfig.api.url.production;
  }

  setBaseURL(baseURL: string) {
    this.httpInstance = Axios.create({
      timeout: appConfig.api.timeout,
      baseURL,
    });
    this.attachInterceptors();
    return this;
  }

  attachInterceptors() {
    this.httpInstance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const token = this.getAuthTokenFromLocalStorage();

        if (token) {
          config.headers.Authorization = `${token}`;
        }

        return config;
      }
    );
  }

  setTokenExpirationStrategy(
    tokenExpireStrategy: () => Promise<string | null>
  ) {
    // this.httpInstance.interceptors.request.clear();
    this.httpInstance.interceptors.request.use(async (config) => {
      const newTokenProvided = await tokenExpireStrategy();
      if (newTokenProvided) {
        this.setAuthorization(newTokenProvided);
        config.headers.Authorization = newTokenProvided;
      }
      return config;
    });
  }

  // Método para armazenar o token no localStorage
  private saveAuthTokenToLocalStorage(token: string): void {
    localStorage.setItem("authToken", token);
  }

  // Método para recuperar o token do localStorage
  private getAuthTokenFromLocalStorage(): string | null {
    return localStorage.getItem("authToken");
  }

  setAuthorization(token: string): void {
    this.saveAuthTokenToLocalStorage(token);
    this.httpInstance.defaults.headers.common.Authorization = token;
  }

  updateToken(): void {
    const token = this.getAuthTokenFromLocalStorage();

    if (token !== null) {
      this.setAuthorization(token);
    }
  }

  get<T = any>(path: string, params?: {}): Promise<T> {
    if (!this.httpInstance.defaults.headers.common.Authorization) {
      this.updateToken();
    }

    return this.httpInstance
      .get<T>(path, params)
      .then(({ data }) => data)
      .catch((err) => {
        const erroApp = err as AppError;
        const error: AxiosError<{ message: string }> = err;

        if (error.response && error.response.status === 403) {
          goToLogout();
        }

        if (
          error.response &&
          (error.response.status === 404 || error.response.status === 500)
        ) {
          throw new AppError(
            `${erroApp.message}`,
            "error",
            `${error.response.status}`
          );
        }

        if (erroApp && erroApp.message && erroApp.status) {
          throw new AppError(
            `${erroApp.message}`,
            "error",
            `${erroApp.status}`
          );
        }

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(
            `${error.response.data.message}`,
            "error",
            `${error.response.status}`
          );
        } else {
          throw new AppError(
            "Não foi possível realizar a operação =(",
            "error"
          );
        }
      });
  }

  getWithHeaders<T = any, U = any>(
    path: string,
    params?: {}
  ): Promise<{ data: T; headers: U }> {
    return this.httpInstance
      .get<T>(path, params)
      .then(({ data, headers }) => ({ data, headers }))
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  post<T = any>(path: string, body: any, params?: {}): Promise<T> {
    return this.httpInstance
      .post<T>(path, body, params)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;

        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(
            `${error.response.data.message}`,
            "error",
            `${err.response.data.status}`
          );
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  delete<T = any>(path: string, config?: {}): Promise<T> {
    return this.httpInstance
      .delete<T>(path, config)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  patch<T = any>(path: string, body?: any, params?: {}): Promise<T> {
    return this.httpInstance
      .patch<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AppError = err;

        if (error.status === "403") {
          goToLogout();
        }

        if (error && error.message) {
          throw new AppError(
            err?.response?.data?.message ?? error.message,
            "error",
            error.status ?? err?.response?.status
          );
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }

  put<T = any>(path: string, body?: any, params?: {}): Promise<T> {
    return this.httpInstance
      .put<T>(path, body, params)
      .then(({ data }) => data)
      .catch((err) => {
        const error: AxiosError<{ message: string }> = err;
        if (error.response && error.response.status === 403) {
          goToLogout();
        }
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          throw new AppError(error.response.data.message, "error");
        } else {
          throw new AppError("Não foi possível realizar a operação", "error");
        }
      });
  }
}
