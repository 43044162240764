import Layout from "@components/Layout";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import { FormProvider } from "./FormContext";
import FormSimulateOrder from "./FormSimulation";
import TemporaryIndexFormSimulateOrder from "./FormSimulation/TemporaryIndexFormSimulateOrder";
import { useFlags } from "flagsmith/react";
import BreadcrumbAndTitle from "@components/BreadcrumbAndTitle";
import ROUTES from "@config/routes";
import { BreadcrumbChild } from "@components/BreadcrumbCustom";

export const reasons = [
  "1 - Carregamento Oliveira Energia ",
  "2 - Carregamento CIF - Posto",
  "3 - Carregamento CIF - Usina",
  "4 - Carregamento FOB",
  "5 - Abastecimento Consumo Interno - Geradores",
  "6 - Abastecimento Consumo Interno - Motor de combate a incêndio",
  "7 - Abastecimento - Outros",
  "8 - Complemento de carregamento CIF",
  "9 - Complemento de carregamento FOB",
  "10 - Complemento de descarga",
  "11 - Erro Ticket - Sem retorno do painel",
  "12 - Erro Ticket - Lado da plataforma de carregamento",
  "13 - Outros",
];

export const NewOrderPage: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const flag_simulate_loading = useFlags([
    "user_sidebar_change_ui_simulate_loading",
  ]);

  const breadcrumbDataOrders: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.HOME,
      label: "Início",
    },
    {
      label: "Simular carregamento",
    },
  ];

  return (
    <Layout>
      <Box
        sx={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <BreadcrumbAndTitle
          breadcrumbData={breadcrumbDataOrders}
          title="Simular carregamento"
        />

        <FormProvider>
          {flag_simulate_loading.user_sidebar_change_ui_simulate_loading
            .enabled ? (
            <FormSimulateOrder />
          ) : (
            <TemporaryIndexFormSimulateOrder />
          )}
        </FormProvider>
      </Box>
    </Layout>
  );
};
