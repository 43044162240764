import React from "react";
import { Theme } from "@material-ui/core";
import { Grid, Hidden } from "@mui/material";

import {
  formatAllFistLetterCapitalized,
  formatDateWithSlash,
  formatToAmountLiters,
  maskCNPJ,
} from "@utils/index";
import { KEY, STATUSROADLOAD, STATUSTYPE } from "@utils/enum";

import Checkbox from "@components/CheckBox";

import {
  TableBodyTypography,
  TableHeadTypography,
  TypographyCaption,
} from "../typography";
import ModalLoadScheduled from "../modal/ModalLoadScheduled";

import { IRoadNextLoad } from "../../model";
import { LIMIT_ORDER_SALES } from "../../context/RoadLoadContext";

export const applyColor = (data, theme: Theme): string => {
  switch (data) {
    case STATUSTYPE.FT:
    case STATUSTYPE.FATURADO:
      return theme.palette.success.light ?? "#0BB873";

    case STATUSTYPE.LIBERADO:
      return theme.palette.secondary.light ?? "#FFB800";

    case STATUSTYPE.BL:
    case STATUSTYPE.BLOQUEADO:
      return theme.palette.error.light ?? "#C30C0C";

    default:
      return theme.palette.primary.highlightedplus ?? "#3E3D3D";
  }
};

export const applyFormatStatus = (data: string | unknown) => {
  switch (data) {
    case STATUSTYPE.FT:
      return "Faturado";
    case STATUSTYPE.BL:
      return "Bloqueio Financeiro";
    default:
      return "";
  }
};

export const multipleHeaders = (multiple, headers, hiddenScheduleButton) => {
  let headersNormalized = headers;
  if (multiple) {
    headersNormalized = [
      { key: KEY.ACTIONCHECK, label: "" },
      ...headersNormalized,
    ];
  }

  if (hiddenScheduleButton) {
    headersNormalized = headersNormalized.filter(
      (header) => header.key !== KEY.ACTION
    );
  }
  return headersNormalized;
};

export const formatHeadEntry = (key, value) => {
  switch (key) {
    case KEY.ICON:
      return <Hidden lgDown>{value()}</Hidden>;
    case KEY.CNPJ:
      return (
        <TableHeadTypography sx={{ py: { xs: 0.2, lg: 1.2 } }}>
          {value}
        </TableHeadTypography>
      );

    default:
      return (
        <TableHeadTypography sx={{ py: { xs: 0.2, lg: 1.2 } }}>
          {formatAllFistLetterCapitalized(value) ?? "-"}
        </TableHeadTypography>
      );
  }
};

export const formatEntry = (
  key: string,
  value,
  index: number,
  rows,
  roadsSelected,
  updateRoadSelected,
  status,
  hiddenScheduleButton,
  multiple,
  theme: Theme,
  Unavailable?: boolean,
  maxLimited?: boolean
) => {
  switch (key) {
    case KEY.ACTIONCHECK:
      return (
        <Checkbox
          checked={roadsSelected?.some(
            (road) => road.order === rows[index].order
          )}
          onChange={() => updateRoadSelected(rows[index])}
          disabled={maxLimited}
        />
      );

    case KEY.ACTION:
      if (index === undefined) return;
      if (status === STATUSROADLOAD.SCHEDULED) {
        const nextLoadItem = rows[index] as IRoadNextLoad;
        return (
          typeof index !== "undefined" && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <ModalLoadScheduled
                key={index}
                nextLoad={nextLoadItem}
                isTable={true}
              >
                Reagendar
              </ModalLoadScheduled>
            </div>
          )
        );
      }

      break;

    case KEY.STATUS:
      return (
        <TableBodyTypography style={{ color: applyColor(value, theme) }}>
          {applyFormatStatus(value)}
        </TableBodyTypography>
      );

    case KEY.DELIVERYDATE:
    case KEY.RELEASEDATE:
    case KEY.INVOICEDATE:
    case KEY.BLOCKEDDATE:
      return (
        <TableBodyTypography>{formatDateWithSlash(value)}</TableBodyTypography>
      );

    case KEY.FREIGHT:
      return <TableBodyTypography>{value}</TableBodyTypography>;

    case KEY.QUANTITY:
      return (
        <TableBodyTypography>{formatToAmountLiters(value)}</TableBodyTypography>
      );

    case KEY.ICON:
      return (
        <Hidden lgDown>
          {Unavailable && (
            <Grid container sx={{ height: 10 }}>
              <Grid
                item
                sx={{
                  borderBottom: "3px solid red",
                  position: "relative",
                  right: "12px",
                  transform: "rotate(-35deg)",
                  background: "#000",
                  color: "#fff",
                  borderRadius: "4px",
                  textAlign: "center",
                  padding: 0.6,
                }}
              >
                <TypographyCaption sx={{ fontSize: 11 }}>
                  Indisponivel
                </TypographyCaption>
              </Grid>
            </Grid>
          )}
          {value()}
        </Hidden>
      );

    case KEY.CNPJ:
      return (
        <TableBodyTypography sx={{ py: { xs: 0.2, lg: 1.2 } }}>
          {maskCNPJ(value)}
        </TableBodyTypography>
      );

    case KEY.CUSTOMER:
      return (
        <TableBodyTypography sx={{ py: { xs: 0.2, lg: 1.2 } }}>
          {`${rows[index]["customerID"]} - ` +
            formatAllFistLetterCapitalized(value)}
        </TableBodyTypography>
      );

    default:
      return (
        <TableBodyTypography sx={{ py: { xs: 0.2, lg: 1.2 } }}>
          {formatAllFistLetterCapitalized(value) ?? "-"}
        </TableBodyTypography>
      );
  }
};

export const UnavailableRow = (schedulable: boolean, status): boolean => {
  if (status === STATUSROADLOAD.WAITING && !schedulable) return true;
  return false;
};

export const BlockedRow = (
  selectedRows,
  currRow,
  amountSalesOrder?: number
): boolean => {
  const maxLimited =
    selectedRows?.length >= LIMIT_ORDER_SALES - (amountSalesOrder ?? 0);

  const wasSelected = selectedRows?.some(
    (selectedRow) => selectedRow.order === currRow.order
  );

  const blockedRow = maxLimited && !wasSelected;
  return blockedRow;
};

export const statusLabel = (status: string): string => {
  switch (status) {
    case STATUSROADLOAD.SCHEDULED:
      return "agendado";

    case STATUSROADLOAD.WAITING:
      return "nao agendado";

    case STATUSROADLOAD.BLOCKED:
      return "bloqueado";

    case STATUSROADLOAD.FINISHED:
      return "finalizado";

    default:
      return "";
  }
};
