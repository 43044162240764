import { BreadcrumbChild } from "@components/BreadcrumbCustom";
import Layout from "@components/Layout";
import ROUTES from "@config/routes";
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import ProductContent from "./Sections/ProductContent";
import SaveProductDrawer from "./Sections/SaveProductDrawer";
import {
  DiscountProductProvider,
  useDiscountProductContext,
} from "./context/ProductContext";
import BreadcrumbAndTitle from "@components/BreadcrumbAndTitle";

const useStyles = makeStyles(() =>
  createStyles({
    formTitle: {
      fontWeight: "bold",
      marginBottom: "2rem",
      marginTop: "1rem",
      color: "#3E3D3D",
    },
    styleButtonContained: {
      borderRadius: "2px",
      textTransform: "none",
      fontWeight: "bold",
      background: "#D91F05",
      boxShadow: "none",
      "&:hover": {
        background: "#D91F31",
      },
    },
  })
);

const ProductContainer: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const {
    openDrawer,
    setOpenDrawer,
    editProduct,
    setEditProduct,
  } = useDiscountProductContext();

  const breadcrumbDataOrders: BreadcrumbChild[] = [
    {
      link: ROUTES.USER_ROUTES.DISCOUNT,
      label: "DESCONTOS",
    },
    {
      label: "Cadastrar produto",
    },
  ];

  return (
    <Layout>
      <Grid item>
        <SaveProductDrawer
          anchortype={"right"}
          open={openDrawer}
          handleClose={() => setOpenDrawer(false)}
          product={editProduct}
        />
      </Grid>
      <Box
        style={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <BreadcrumbAndTitle
          breadcrumbData={breadcrumbDataOrders}
          title="Produtos"
        />
      </Box>

      <Box
        style={{
          margin: isMobile ? 0 : "0 4rem",
          width: isMobile ? "100%" : "auto",
          padding: isMobile ? "2rem" : "1rem",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="primary"
            type="submit"
            variant="contained"
            className={classes.styleButtonContained}
            onClick={() => {
              setEditProduct(undefined);
              setOpenDrawer(true);
            }}
          >
            Cadastrar produto
          </Button>
        </Grid>

        <Grid item xs={12}>
          <ProductContent />
        </Grid>
      </Box>
    </Layout>
  );
};

const Product: React.FC = () => {
  return (
    <DiscountProductProvider>
      <ProductContainer />
    </DiscountProductProvider>
  );
};

export default Product;
